import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-us-sales-tax-report',
  templateUrl: './us-sales-tax-report.component.html',
  styleUrls: ['./us-sales-tax-report.component.css']
})
export class UsSalesTaxReportComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  activeLink = 'All Reports';
  links = [
    // { linkTitle: 'All Reports', linkRoute: '/us-sales-tax-report' },
    // { linkTitle: 'Recent Reports', linkRoute: '/us-sales-tax-report-recent' }
  ]

  override displayedColumns: string[] = [
    'title',
    'field_date_and_time',
    'field_gross_tuition',
    'field_payment_id',
    'field_payment_type',
    'field_status',
    'field_student_name',
    'field_tax_collected',
    'field_total_payment_amount',
    // 'actions'
  ];

  dateTimeRange: Date[] = [
    new Date(new Date().getFullYear(), 0, 1),
    new Date()
  ];

  override ngOnInit() {
    const params: { parameter: string; value: string; }[] = [{
      parameter: 'field_date_and_time',
      value: `${moment(this.dateTimeRange[0]).format('YYYY-MM-DD')}--${moment(this.dateTimeRange[1]).format('YYYY-MM-DD')}`
    }];

    this.getData(params);
  }

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    let filters = '?sort_order=ASC&sort_by=field_date_and_time';
    if (params) {
      for (const param of params) {
        console.log('Processing param:', param);
        if (param.parameter === 'field_date_and_time' && param.value) {
          filters += `&field_date_and_time=${param.value}`;
        } else if (param.parameter !== 'dateRange' && param.parameter !== 'field_date_and_time') {
          filters += `&${param.parameter}=${param.value}`;
        }
      }
    }

    const endpoint = `/api_rest/v1/us-sales-tax-report${filters}`;
    console.log('Final endpoint:', endpoint);

    this._drupalRESTService.httpGET(endpoint, []).subscribe(
      (data) => {
        this.ShowProgressBar = false;
        this.dataSource = new MatTableDataSource(data['results']);
      },
      error => this.handleError(error)
    );
  }

  setReportStatus(payment_id: number, value: string) {
    let body = {
      field_status: value,
    };
    let setReportStatus = this._entityRESTService.patchEntity('us_sales_tax_report', 'us_sales_tax_report', payment_id, body);
    setReportStatus.subscribe(data => {
      console.log('data', data);
      this.getData(this.queryParams);
    })
  }

  override onSubmit(form: NgForm) {
    const params: { parameter: string; value: string; }[] = [];

    if (this.dateTimeRange[0] && this.dateTimeRange[1]) {
      params.push({
        parameter: 'field_date_and_time',
        value: `${moment(this.dateTimeRange[0]).format('YYYY-MM-DD')}--${moment(this.dateTimeRange[1]).format('YYYY-MM-DD')}`
      });
    }

    Object.keys(form.value).forEach(key => {
      if (form.value[key] && key !== 'dateRange') {
        params.push({
          parameter: key,
          value: form.value[key]
        });
      }
    });

    this.getData(params);
  }

  dateRangeChange(event: any) {
    console.log('Date Range Change Event:', event);
    if (event.value && Array.isArray(event.value)) {
      this.dateTimeRange = event.value;
      const params: { parameter: string; value: string; }[] = [{
        parameter: 'field_date_and_time',
        value: `${moment(this.dateTimeRange[0]).format('YYYY-MM-DD')}--${moment(this.dateTimeRange[1]).format('YYYY-MM-DD')}`
      }];
      this.getData(params);
    }
  }

  override resetForm() {
    this.dateTimeRange = [
      new Date(new Date().getFullYear(), 0, 1),
      new Date()
    ];

    const params: { parameter: string; value: string; }[] = [{
      parameter: 'field_date_and_time',
      value: `${moment(this.dateTimeRange[0]).format('YYYY-MM-DD')}--${moment(this.dateTimeRange[1]).format('YYYY-MM-DD')}`
    }];

    this.getData(params);
  }

  getFormattedDateRange(): string {
    if (this.dateTimeRange && this.dateTimeRange[0] && this.dateTimeRange[1]) {
      return `${moment(this.dateTimeRange[0]).format('MM/DD/YYYY')} - ${moment(this.dateTimeRange[1]).format('MM/DD/YYYY')}`;
    }
    return '';
  }

}
