import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ComponentCanDeactivate } from 'src/app/guards/unsaved-changes.guard';
import { SimpleImportHistoryComponent } from '../simple-import-history/simple-import-history.component';

@Component({
  selector: 'app-simple-import-history-wrapper',
  templateUrl: './simple-import-history-wrapper.component.html',
  styleUrls: ['./simple-import-history-wrapper.component.css']
})
export class SimpleImportHistoryWrapperComponent implements ComponentCanDeactivate, AfterViewInit {
  @ViewChild(SimpleImportHistoryComponent) historyComponent: SimpleImportHistoryComponent;
  private initialized = false;

  ngAfterViewInit() {
    this.initialized = true;
  }

  canDeactivate(): boolean {
    if (!this.initialized) return true;
    return this.historyComponent?.canDeactivate() ?? true;
  }

  onFormChange(): void {
    if (this.historyComponent) {
      this.historyComponent.onFormChange();
    }
  }

  onSubmitSuccess(): void {
    if (this.historyComponent) {
      this.historyComponent.onSubmitSuccess();
    }
  }
}
