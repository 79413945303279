<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

<mat-checkbox class="me-3" *ngIf="enrollmentsToLoad == 'Non-Unit'" [(ngModel)]="sendNonUnitPrivateParam"
              (change)="updateGetData()">Non Unit Private</mat-checkbox>

<mat-checkbox *ngIf="enrollmentsToLoad == 'Non-Unit'" [(ngModel)]="sendNonUnitGroupParam"
              (change)="updateGetData()">Non Unit Group</mat-checkbox>

<div class="">
    <div class="col-md-12 pt-3 pb-3">
        <div class="float-end">
            <button class="enrollment" mat-flat-button
            (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'create', null, {
                field_student: drupal_student_name + ' (' + drupal_studentAccount_id + ')'
            })" mat-button>
            Add Enrollment
            </button>
        </div>
    </div>
</div>

<div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource"
        matSort
        (matSortChange)="sortData($event)"
        class="mt-4">

        <!-- enrollmentID Column -->
        <ng-container matColumnDef="enrollmentID">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by enrollmentID">
                ID
            </th>
            <td mat-cell *matCellDef="let element"> {{element.id}}
            </td>
        </ng-container>

        <!-- field_contacts Column -->
        <ng-container matColumnDef="field_contacts">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_contacts">
                Student
            </th>
            <td mat-cell *matCellDef="let element"> {{element.field_contacts}}
            </td>
        </ng-container>

        <!-- field_sale_date Column -->
        <ng-container matColumnDef="field_sale_date">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_sale_date">
                Sale Date
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_sale_date | date: 'MM/dd/yyyy' }} </td>
        </ng-container>

        <!-- field_instructor Column -->
        <ng-container matColumnDef="field_instructor">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_instructor">
                Instructor
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_first_name | htmlDecode }} {{ element.field_last_name | htmlDecode }}</td>
        </ng-container>

        <!-- field_category Column -->
        <ng-container matColumnDef="field_category">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_category">
                Category
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_category }}
            </td>
        </ng-container>

        <!-- field_package_name Column -->
        <ng-container matColumnDef="field_package_name">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_package_name">
                Package Name
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_enrollment_package_name }} </td>
        </ng-container>

        <!-- field_enrollment_lesson_count Column -->
        <ng-container matColumnDef="field_enrollment_lesson_count">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_enrollment_lesson_count">
                # of Lessons
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_enrollment_lesson_count }} </td>
        </ng-container>

        <!-- field_enrollment_total_price Column -->
        <ng-container matColumnDef="field_enrollment_total_price">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_enrollment_total_price">
                Total Price
            </th>
            <td mat-cell *matCellDef="let element"> ${{ element.field_enrollment_total_price }} </td>
        </ng-container>

        <!-- field_total_paid Column -->
        <ng-container matColumnDef="field_total_paid">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_total_paid">
                Total Paid
            </th>
            <td mat-cell *matCellDef="let element"> <ng-container *ngIf="element.field_total_payments_made">${{ element.field_total_payments_made }}</ng-container> </td>
        </ng-container>

        <!-- field_balance_due Column -->
        <ng-container matColumnDef="field_balance_due">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_balance_due">
                Total Due
            </th>
            <td mat-cell *matCellDef="let element"> <ng-container *ngIf="element.field_balance_due">${{ element.field_balance_due }}</ng-container> </td>
        </ng-container>

        <!-- field_additional_notes Column -->
        <ng-container matColumnDef="field_additional_notes">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_additional_notes">
                Last Paid
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_last_paid_date != "" ? (element.field_last_paid_date | date) : element.field_additional_notes | date }} </td>
        </ng-container>

        <!-- field_lesson_available Column -->
        <ng-container matColumnDef="field_lesson_available">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_lesson_available">
                Lessons Available
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.field_lesson_available }} </td>
        </ng-container>

        <!-- field_lesson_available Column -->
        <ng-container matColumnDef="isDropped">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_lesson_available">
                Drop
            </th>
            <td mat-cell *matCellDef="let element"><span class="text-nowrap">{{ element.field_dropped_date ? '✓' : ''  }} {{ element.field_dropped_date | date }}</span></td>
        </ng-container>

        <!-- nothing Column -->
        <!-- <ng-container matColumnDef="nothing">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by nothing">
                Hide
            </th>
            <td mat-cell *matCellDef="let element">
            </td>
        </ng-container> -->

        <mat-menu #enrollmentMenu="matMenu">
            <ng-template matMenuContent let-enrollmentID="enrollmentID" let-student_account_id="student_account_id">
                <button mat-menu-item
                    (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', '0', enrollmentID)"
                    >
                    View
                </button>
                <button mat-menu-item
                    (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'editEnrollment', enrollmentID)"
                    >
                    Edit
                </button>
                <button mat-menu-item
                    (click)="openEntityComponent(SignatureEnrollmentComponent, 'packages', 'enrollment', 'signEnrollment', enrollmentID)"
                    >
                    Sign
                </button>
                <button mat-menu-item
                    (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'delete', enrollmentID)"
                    >
                    Delete
                </button>
                <button mat-menu-item
                    (click)="openEntityComponent(EnrollmentDropComponent, 'packages', 'enrollment', 'drop', enrollmentID)"
                    >
                    Drop
                </button>
                <button mat-menu-item
                    (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'refund', null, {field_gross_tuition: '', field_enrollment_name: {target_id: enrollmentID }, field_student_name: student_account_id, field_payment_type: '71'})"
                    >
                    Refund
                </button>
                <button mat-menu-item
                    (click)="openEntityComponentXL(EnrollmentViewLessonComponent, 'packages', 'enrollment', '0', enrollmentID, enrollmentID)"
                    >
                    View Lessons
                </button>
                <button mat-menu-item
                    (click)="openEntityComponent(ViewPaymentsFromEnrollmentComponent, 'packages', 'enrollment', null, enrollmentID)"
                    >
                    View Payments
                </button>
            </ng-template>
        </mat-menu>

        <!-- nothing_1 Column -->
        <ng-container matColumnDef="nothing_1">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by nothing_1">
                Actions
            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="enrollmentMenu" [matMenuTriggerData]="{ enrollmentID: element.id, student_account_id: element.field_contacts + ' (' + element.student_account_id + ')'}">
                <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'dropped-enrollment': row.field_dropped_date}">
        </tr>
    </table>



    <ng-container *ngIf="!this.data?.['count'] && this.data?.['count'] < 1">
        <div>
        No enrollments are available.
        </div>
    </ng-container>

    <mat-paginator
    [disabled]="ShowProgressBar"
    (page)="pageChanged($event)"
    [pageSizeOptions]="[data?.['count']]"
    [length]="data?.['count'] || 0"
    [pageSize]="paginationTotalPages"
    [pageIndex]="paginationIndex"
    aria-label="Select page of view"></mat-paginator>

</div>
