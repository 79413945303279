import { CommonModule } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { ActivatedRoute, Event, Params, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from "moment";
import { ViewTableComponent } from "../../base/view-table/view-table.component";

@Component({
  selector: 'app-students-list',
  templateUrl: './inquiry-view.component.html',
  styleUrls: [
    './inquiry-view.component.css',
    '../../../app.component.css'
  ]
})

export class InquiryViewComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'field_first_name',
    'field_last_name',
    'field_inquired',
    'field_booked_on',
    'field_showed',
    'inquiry_taker',
    'teacher',
    'field_email',
    'field_cell_phone',
    'field_inquiry_method',
    'field_notes',
    'actions',
  ];

  override getData(params) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting parameters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    this._utilityService.getInquiryView(this.queryParams)
      .subscribe((data) => {
        this.ShowProgressBar = false;
        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Update pagination using the parent class method
        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
      error => this.handleError(error)
    );
  }
}
