import { ComponentType } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import moment from 'moment';
import { StudentDashboardWrapperComponent } from '../../student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { CancelFutureAppointmentComponent } from '../cancel-future-appointment/cancel-future-appointment.component';
import { CancelRescheduleComponent } from '../cancel-reschedule/cancel-reschedule.component';
import { EnrollmentEntityComponent } from '../enrollment-entity/enrollment-entity.component';
import { EventGroupLessonEntityComponent } from '../event-group-lesson-entity/event-group-lesson-entity.component';
import { EventLessonEntityComponent } from '../event-lesson-entity/event-lesson-entity.component';
import { EventSchedulesEntityComponent } from '../event-schedules-entity/event-schedules-entity.component';
import { EventServicesEntityComponent } from '../event-services-entity/event-services-entity.component';
import { PaymentsEntityComponent } from '../payments-entity/payments-entity.component';
import { PostLessonComponent } from '../post-lesson/post-lesson.component';
import { CancelFutureServiceComponent } from '../cancel-future-service/cancel-future-service.component';
import { BaseComponent } from '../base-form/base-form.component';
import { UpdateStatusGroupLessonComponent } from '../update-status-group-lesson/update-status-group-lesson.component';
import { MatTabChangeEvent } from '@angular/material/tabs';


@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: [
    './appointment-details.component.css',
    '../../../../app.component.css',
  ]
})
export class AppointmentDetailsComponent extends BaseDialogComponent implements OnInit {

  @Output() eventDeleted = new EventEmitter<void>();

  entityID = this.injectedDialogData?.event?._def?.extendedProps?.entityId;
  eventData = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails;
  attendeesID = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.studentsDashboardStatus?.[0]?.attendees_id;
  studentName = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails.studentsDashboardStatus?.[0]?.name;
  eventID = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails.studentsDashboardStatus?.[0]?.entity_id;
  status = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails.studentsDashboardStatus?.[0]?.status;
  appointmentType = this.injectedDialogData?.event?._def?.extendedProps?.appointmentType;
  studioInformationConfig: any;
  eventEntityData: Object;
  flag2: boolean;
  flag1: boolean;
  isLoading = false;

  override lessonBlockForm = this._formBuilder.group({
    field_material_covered: [null],
    field_comments: [null],
    field_lesson_rating: [null],
  });

  detectSignatureInstructor: boolean;
  detectSignatureStudent: boolean;

  @ViewChild('SignatureStudent') signatureStudent: ElementRef<HTMLCanvasElement>;
  @ViewChild('SignatureInstructor') signatureInstructor: ElementRef<HTMLCanvasElement>;
  @ViewChild(BaseComponent) baseComponent: BaseComponent;
  @ViewChild('updateStatusGroupLesson') updateStatusGroupLesson: UpdateStatusGroupLessonComponent;

  // Component refs
  EventLessonEntityComponent = EventLessonEntityComponent;
  EventServicesEntityComponent = EventServicesEntityComponent;
  PaymentEntityComponent = PaymentsEntityComponent;
  EventScheduleEntityComponent = EventSchedulesEntityComponent;
  EventGroupLessonEntityComponent = EventGroupLessonEntityComponent;
  CancelRescheduleComponent = CancelRescheduleComponent;
  PostLessonComponent = PostLessonComponent;
  EnrollmentEntityComponent = EnrollmentEntityComponent;
  CancelFutureAppointmentComponent = CancelFutureAppointmentComponent;
  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;
  CancelFutureServiceComponent = CancelFutureServiceComponent;
  BaseComponent = BaseComponent;


  override ngAfterViewInit() {
    console.log('this.injectedDialogData', this.injectedDialogData);
    // Signature stuff.
    this.signatureInit(this.signatureStudent, 'student');
    this.signatureInit(this.signatureInstructor, 'instructor');

    this._drupalRESTService.httpGET('/api_rest/v1/getStudioInformation')
      .subscribe(data => {
        this.studioInformationConfig = data;
        console.log('Studio Information:', this.studioInformationConfig);
      }, error => {
        console.error('Error fetching studio information:', error);
      });

    if (this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'lesson') {
      // this._entityRESTService.getEntity('events', 'lesson', this.entityID).subscribe((data) => {
      //   this.eventEntityData = this.entityData = data;
      //   // this.setFormValues(this.lessonBlockForm)
      // });

      this.loadLessonData(this.entityID).subscribe((data) => {
        this.eventEntityData = data;
        console.log('eventEntityData', this.eventEntityData);
      });
    }

    // Lesson blocks form load.
    this.getEntity('events', 'lesson', this.entityID, null);
    this.loadLessonData(this.entityID).subscribe((data) => {

    });
    // this.getEntity('events', 'lesson', this.entityID, this.lessonBlockForm);
  }

  /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
  openDialog(component: ComponentType<unknown>, dialogConfig: { height: string, width: string, closeOnNavigation?: boolean, data?: {} }, data) {
    let dialogRef = this._dialogService.openDialog(component, dialogConfig ?? "defaultWithData", {
      data,
    }).afterClosed().subscribe(data => {
      // this.refreshCalendar();
    });
  }

  override openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    return this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID ?? '',
        eckType: eckType ?? '',
        bundle: bundle ?? '',
        action: action ?? '',
        fieldsData: fieldsData ?? '',
      },
    });
  }

  nextPaymentOnly(field_scheduled_payments?) {
    if (field_scheduled_payments) {
      let only1 = false;
      return field_scheduled_payments.filter((field_scheduled_payment) => {
        if (only1 == false && moment(field_scheduled_payment.field_payment_date).isSameOrAfter(moment().subtract(1, "days"))) {
          only1 = true;
          return true;
        }
      })
    }
  }

  lessonBlockFormSubmit(f?, action?) {
    console.log('lessonBlockFormSubmit called');
    this.displayProgressSpinner(false);
    this.isLoading = true;

    if (this.eventEntityData?.['field_instructor_signature']?.[0]?.['url'] != null) {
      console.log('Instructor signature URL exists');
      this.flag1 = true;
      this.flag2 = true;
      this.referenceUploadedFileComplete(action);
    }

    // Prevent uploading blank signatures on edit.
    if (this.eventEntityData?.['field_instructor_signature']?.[0]?.['url'] == null) {
      console.log('Instructor signature URL is null');
      let signatureStudent = this.signatureStudent.nativeElement.toBlob(blob => {
        console.log('Student signature blob:', blob);
        this._drupalRESTService.uploadFile('events', 'lesson', 'field_signature_student', 'signature_student_lesson_block_' + this.entityID + '.png', blob)
          .subscribe(data => {
            console.log('Student signature upload response:', data);
            if (data['fid']?.[0]?.['value']) {
              if (typeof this.entityData?.['id'] == 'undefined') {
                console.error('Missing entity ID');
                alert('Missing entity ID.');
              } else {
                console.log('Patching student signature to entity');
                this._entityRESTService.patchEntity('events', 'lesson', this.entityID, {
                  "field_signature_student": [
                    {
                      "target_id": data['fid'][0]['value'],
                      "description": ""
                    }
                  ]
                }).subscribe(data => {
                  console.log('Student signature patch response:', data);
                  this.flag1 = true;
                  this.referenceUploadedFileComplete(action);
                });
              }
            }
          });
      });

      let signatureInstructor = this.signatureInstructor.nativeElement.toBlob(blob => {
        console.log('Instructor signature blob:', blob);
        this._drupalRESTService.uploadFile('events', 'lesson', 'field_instructor_signature', 'signature_instructor_lesson_block_' + this.entityID + '.png', blob)
          .subscribe(data => {
            console.log('Instructor signature upload response:', data);
            if (data['fid']?.[0]?.['value']) {
              if (typeof this.entityData?.['id'] == 'undefined') {
                console.error('Missing entity ID');
                alert('Missing entity ID.');
              } else {
                console.log('Patching instructor signature to entity');
                this._entityRESTService.patchEntity('events', 'lesson', this.entityID, {
                  "field_instructor_signature": [
                    {
                      "target_id": data['fid'][0]['value'],
                      "description": ""
                    }
                  ]
                }).subscribe(data => {
                  console.log('Instructor signature patch response:', data);
                  this.flag2 = true;
                  this.referenceUploadedFileComplete(action);
                });
              }
            }
          });
      });
    }
  }


  signatureClearStudent() {
    this.signatureClear(this.signatureStudent);
    this.detectSignatureStudent = false;
  }

  signatureClearInstructor() {
    this.signatureClear(this.signatureInstructor);
    this.detectSignatureInstructor = false;
  }

  signatureClear(elementRef: ElementRef<HTMLCanvasElement>) {
    let canvas = elementRef;

    let context = canvas.nativeElement.getContext('2d');
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
  }

  referenceUploadedFileComplete(action?) {
    if (!this.flag1 || !this.flag2) {
      return;
    }

    // Save the entity
    this._entityRESTService.patchEntity('events', 'lesson', this.entityID, this.lessonBlockForm.value)
      .subscribe(
        data => {
          console.log('data', data);

          if (action === 'email') {
            // Send the lesson block email
            this.sendLessonBlockEmail();
          } else {
            // Close the dialog
            this.closeDialog();
            this.displayProgressSpinner(false);
            this.isLoading = false;
          }
        },
        error => this.handleError(error)
      );
  }

  signatureInit(elementRef: ElementRef<HTMLCanvasElement>, elementName: string) {
    // Signature Stuff
    let canvas = elementRef;
    let context = canvas?.nativeElement?.getContext('2d');

    if (!context) { return };

    context.lineCap = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 3;

    let x = 0, y = 0;
    let isMouseDown = false;

    let stopDrawing = () => { isMouseDown = false; }
    let startDrawing = (event: MouseEvent) => {
      if (elementName == 'student') {
        this.detectSignatureStudent = true;
      }
      if (elementName == 'instructor') {
        this.detectSignatureInstructor = true;
      }
      isMouseDown = true;
      [x, y] = [event.offsetX, event.offsetY];
    }
    let drawLine = (event: any) => {
      let newY, newX;

      if (isMouseDown) {
        newX = event.offsetX;
        newY = event.offsetY;

        context.beginPath();
        context.moveTo(x, y);
        context.lineTo(newX, newY);
        context.stroke();
        x = newX;
        y = newY;
      }
    }

    // Add event listeners.
    if (this.isTablet) {
      // Support tablets.
      canvas.nativeElement.addEventListener('touchstart', startDrawing);
      canvas.nativeElement.addEventListener('pointermove', drawLine);
      canvas.nativeElement.addEventListener('touchend', stopDrawing);
      canvas.nativeElement.addEventListener('touchcancel', stopDrawing);
    } else {
      canvas.nativeElement.addEventListener('mousedown', startDrawing);
      canvas.nativeElement.addEventListener('mousemove', drawLine);
      canvas.nativeElement.addEventListener('mouseup', stopDrawing);
      canvas.nativeElement.addEventListener('mouseout', stopDrawing);
    }
  }

  processEventDetails() {
    const eventDetails = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails;
    if (!eventDetails) return;

    const startDate = moment(eventDetails.startDate.join(' '));
    const endDate = moment(eventDetails.endDate.join(' '));

    const processedDetails = {
      date: startDate.format('LL'), // Assuming date is the first element
      startTime: startDate.format('h:mm A'), // Format like "1:00 PM"
      endTime: endDate.format('h:mm A') // Format like "2:00 PM"
    };

    return processedDetails;
  }

  sendLessonBlockEmail() {
    // Get the event ID from the current entity
    const eventID = this.entityID;

    // Make an API call to send the lesson block email
    this._drupalRESTService.httpGET('/api_rest/v1/sendLessonBlockEmail?eventID=' + eventID)
      .subscribe(
        data => {
          console.log('API response:', data);
          if (data?.['error']) {
            // Handle specific error response
            const errorMessage = data?.['error'];
            this.handleSendEmailError(errorMessage);
          } else {
            // Handle success response
            this._snackBar.open('Email sent successfully.', 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
            });
            // Close the dialog
            this.closeDialog();
            this.displayProgressSpinner(false);
            this.isLoading = false;
          }
        },
        error => {
          console.error('Error sending email:', error);
          // Handle generic error
          const errorMessage = 'An error occurred while sending the email.';
          this.handleSendEmailError(errorMessage);
        }
      );
  }

  handleSendEmailError(errorMessage: string) {
    // Log the error message
    console.error('Error sending email:', errorMessage);

    // Display the error message using the snackbar
    this._snackBar.open(errorMessage, 'Close', {
      duration: 5000,
      horizontalPosition: 'center',
      panelClass: 'error-snackbar',
    });

    // Close the dialog
    this.closeDialog();
    this.displayProgressSpinner(false);
    this.isLoading = false;
  }

  sendEmailLessonReminder(eventID) {
    this._drupalRESTService.httpGET('/api_rest/v1/sendLessonReminderEmail?eventID=' + eventID)
      .subscribe(
        data => {
          console.log('API response:', data);
          if (data['error']) {
            // Handle specific error response
            const errorMessage = data['error'];
            this._snackBar.open(errorMessage, 'Close', {
              duration: 5000,
              horizontalPosition: 'center',
              panelClass: ['error-snackbar'],
            });
          } else if (data['status'] === 'error') {
            const errorMessage = data['message'] || 'An error occurred while sending the email.';
            this._snackBar.open(errorMessage, 'Close', {
              duration: 5000,
              horizontalPosition: 'center',
              panelClass: ['error-snackbar'],
            });
          } else {
            // Handle success response
            this._snackBar.open('Email sent successfully.', 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
            });
          }
        },
        error => {
          console.error('Error sending email:', error);
          this._snackBar.open('An error occurred while sending the email.', 'Close', {
            duration: 5000,
            horizontalPosition: 'center',
            panelClass: ['error-snackbar'],
          });
        }
      );
  }


  sendSMSLessonReminder(eventID: string) {
    this._drupalRESTService.httpGET('/api_rest/v1/sendLessonReminderSMS?eventID=' + eventID)
      .subscribe(data => {
        if (data && typeof data === 'object' && 'error' in data) {
          console.error('Error sending SMS:', data?.['error']);
          const errorMessage = this.formatErrorMessage(data?.['error']);
          // Snackbar message that is centered.
          this._snackBar.open('There was an error. Reason: ' + errorMessage, 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
          });
        } else {
          console.log('SMS sent:', data);
          // Snackbar message that is centered.
          this._snackBar.open('SMS sent successfully.', 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
          });
        }
      }, error => {
        console.error('Error sending SMS:', error);
        const errorMessage = this.formatErrorMessage(error);
        // Snackbar message that is centered.
        this._snackBar.open('There was an error. Reason: ' + errorMessage, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
        });
      });
  }

  private formatErrorMessage(error: any): string {
    if (typeof error === 'string') {
      try {
        const errorObj = JSON.parse(error);
        return errorObj.error || 'Unknown error occurred';
      } catch (e) {
        return error;
      }
    } else if (error && error.message) {
      return error.message;
    }
    return 'Unknown error occurred';
  }

  getFormattedPhoneNumbers(): string {
    const phoneNumbers = this.injectedDialogData?.event?._def?.extendedProps?.studentDetails?.studentPhoneNumbers;
    if (phoneNumbers && phoneNumbers.length) {
      return phoneNumbers.map(phone => this.formatPhoneNumber(phone)).join(', ');
    }
    return 'Disabled';
  }

  formatPhoneNumber(phone: string): string {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
  }

  getJoinedStudentNames(): string {
    const studentNames = this.injectedDialogData?.event?._def?.extendedProps?.studentDetails?.studentNamesFull;
    return Array.isArray(studentNames)
      ? studentNames.join(', ')
      : 'Not Available';
  }

  convertPotentialLesson() {
    const eventID = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.id;
    const data = {
      eventID: eventID
    };

    this._drupalRESTService.httpPOST('/api_rest/v1/convertPotentialLesson', data)
      .subscribe(
        response => {
          console.log('Conversion response:', response);
          if (response.success) {
            // Handle success scenario
            this._snackBar.open('Potential lesson converted successfully.', 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
            });
            // Refresh the calendar or perform any other necessary actions
            this.eventDeleted.emit();
            this.closeDialog();
          } else {
            // Handle error scenario with a specific error message
            const errorMessage = response.message || 'Failed to convert potential lesson.';
            this._snackBar.open(errorMessage, 'Close', {
              duration: 5000,
              horizontalPosition: 'center',
              panelClass: ['error-snackbar'],
            });
          }
        },
        error => {
          console.error('Conversion error:', error);
          // Handle generic error scenario
          this._snackBar.open('An error occurred while converting the potential lesson.', 'Close', {
            duration: 5000,
            horizontalPosition: 'center',
            panelClass: ['error-snackbar'],
          });
        }
      );
  }

  private setInstructorPercentages(enrollmentComponent: EnrollmentEntityComponent, studentData: any) {
    if (studentData?.['field_teacher']?.uid) {
      const instructorPercentagesArray = enrollmentComponent.enrollmentForm.get('field_instructor_percentages') as FormArray;

      // Clear existing entries
      while (instructorPercentagesArray.length !== 0) {
        instructorPercentagesArray.removeAt(0);
      }

      // Add new entry
      const newInstructorPercentage = this._formBuilder.group({
        id: [null],
        field_amount: [null],
        field_instructor: [studentData['field_teacher'].uid, Validators.required],
        field_percentage: [100, Validators.required],
        field_studio_reference: [this._authService.studios?.[0]?.id],
        bundle: ['instructor_percentages', Validators.required],
        type: ['instructor_percentages', Validators.required],
      });

      instructorPercentagesArray.push(newInstructorPercentage);

      // Trigger change detection
      enrollmentComponent.enrollmentForm.updateValueAndValidity();
      console.log('Updated enrollment form:', enrollmentComponent.enrollmentForm.value);
    }
  }

  openEnrollment() {
    const studentAccountId = this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.studentAccountId;
    const studentDisplayName = this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.StudentDisplayName;

    if (!studentAccountId || !studentDisplayName) {
      console.error('Student account ID or display name is undefined');
      // You might want to show an error message to the user here
      return;
    }

    this._entityRESTService.getEntity('student_accounts', 'student_account', studentAccountId)
      .subscribe(
        studentData => {
          const fieldsData = {
            field_student: `${studentDisplayName} (${studentAccountId})`,
            field_executive: studentData?.['field_executive']?.uid || '',
            field_sale_date: moment().format('YYYY-MM-DD'),
            field_expiration_date: moment().add(6, 'months').format('YYYY-MM-DD'),
            field_enrollment_package_name: null,
            field_enrollment_lesson_price: null,
            field_enrollment_lesson_count: null,
            field_enrollment_total_price: null,
            field_total_price_with_est_tax: null,
            field_tax_percentage: null,
            field_discount: 0,
            field_discount_type: 'flat',
            field_enrollment_status: 'Open',
            field_visibility: false,
            giftCertificates: []
          };

          console.log('Enrollment fields data:', fieldsData);

          const dialogRef = this.openEntityComponent(
            EnrollmentEntityComponent,
            'packages',
            'enrollment',
            'create',
            null,
            fieldsData
          );

          dialogRef.afterOpened().subscribe(() => {
            const enrollmentComponent = dialogRef.componentInstance as EnrollmentEntityComponent;
            if (enrollmentComponent && enrollmentComponent.enrollmentForm) {
              this.setInstructorPercentages(enrollmentComponent, studentData);
            }
          });
        },
        error => {
          console.error('Error fetching student data:', error);
          // You might want to show an error message to the user here
        }
      );
  }

  openLesson() {
    const studentAccountId = this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.studentAccountId;
    const studentDisplayName = this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.StudentDisplayName;

    if (!studentAccountId || !studentDisplayName) {
      console.error('Student account ID or display name is undefined');
      this._snackBar.open('Unable to open lesson: Student information is missing', 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
      });
      return;
    }

    const studentIdentifier = `${studentDisplayName} (${studentAccountId})`;

    // First, get the student account data
    this._entityRESTService.getEntity('student_accounts', 'student_account', studentAccountId)
      .subscribe(
        studentData => {
          // Now, get the enrollment data
          const endpoint = "/api_rest/v1/enrollmentLookupDetailed";
          const params = [{ parameter: "u", value: studentAccountId }];

          this._drupalRESTService.httpGET(endpoint, params)
            .subscribe(
              enrollmentData => {
                const dataObj = Object.values(enrollmentData);

                // Filter enrollments
                const availableEnrollments = dataObj.filter(object => {
                  const isPaymentPlusDropEqualTotalPrice = !(object.field_drop[0]?.value == object.field_balance_due[0]?.value);
                  const isLessonAvailable = object.field_lesson_available[0]?.value > 0;
                  const isSundryOrMisc = (object?.field_category?.[0]?.target_id == 67) || (object.field_category[0].target_id == 5);
                  const isLessonTakenAndPaid = (object.field_lesson_available[0]?.value == 0 && object.field_balance_due[0]?.value == 0);

                  return isLessonAvailable && isPaymentPlusDropEqualTotalPrice && !isSundryOrMisc && !isLessonTakenAndPaid;
                });

                // Sort enrollments by sale date and get the oldest one
                availableEnrollments.sort((a, b) => {
                  const dateA = new Date(a.field_sale_date[0].value);
                  const dateB = new Date(b.field_sale_date[0].value);
                  return dateA.getTime() - dateB.getTime();
                });

                const oldestEnrollment = availableEnrollments[0];

                if (!oldestEnrollment) {
                  // this._snackBar.open('No available enrollments found for this student', 'Close', {
                  //   duration: 3000,
                  //   horizontalPosition: 'center',
                  // });
                  return;
                }

                // Prepare the fields data for the lesson
                const fieldsData = {
                  field_instructor: this.injectedDialogData?.event?._def?.extendedProps?.instructorID,
                  field_type: this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.eventTypeID,
                  field_duration: this.injectedDialogData?.event?._def.extendedProps?.eventDetails?.data?.eventDuration,
                  field_date_and_time: this.roundToNearest15Min(),
                  field_status: '58', // Assuming '58' is the status for a new lesson
                  field_studio_reference: this._authService.studios?.[0]?.id,
                  __field_students_inline_form: {
                    type: 'attendees',
                    bundle: 'attendance',
                    field_student_account: studentIdentifier,
                    field_enrollment: oldestEnrollment.id[0].value,
                  }
                };

                // Open the EventLessonEntityComponent dialog
                const dialogRef = this.openEntityComponent(
                  EventLessonEntityComponent,
                  'events',
                  'lesson',
                  'create',
                  null,
                  fieldsData
                );

                dialogRef.afterOpened().subscribe(() => {
                  const lessonComponent = dialogRef.componentInstance as EventLessonEntityComponent;
                  if (lessonComponent && lessonComponent.f) {
                    // Implement lookupStudentAccountContact logic
                    let contacts = "";
                    let contactsID = [];
                    let tmp = [];
                    let selectOptions = [];

                    if (Array.isArray(studentData['field_contacts'])) {
                      studentData['field_contacts'].forEach(element => {
                        contactsID.push(element.id);
                        tmp.push(element.title + ' (' + element.id + ')');
                        selectOptions.push({ id: element.id, title: element.title });
                      });

                      contacts = tmp.join(', ');
                      selectOptions.push({ id: contactsID.toString(), title: contacts });
                    } else {
                      selectOptions.push({ id: studentData['field_contacts'].id, title: studentData['field_contacts'].title });
                      contacts += studentData['field_contacts'].title + ' (' + studentData['field_contacts'].id + ')';
                    }

                    lessonComponent.autoCompleteStudentContactOptions = selectOptions;
                    lessonComponent.f.form.controls['__field_students_inline_form'].patchValue({
                      field_students_contact: contacts
                    });

                    // Automatically select the last contact (the couple) for field_students
                    if (selectOptions.length > 0) {
                      const lastContactId = selectOptions[selectOptions.length - 1].id;
                      lessonComponent.f.form.controls['__field_students_inline_form'].patchValue({
                        field_students: lastContactId
                      });

                      // Set field_type based on field_student_department
                      const studentDepartment = studentData['field_student_department'];
                      if (studentDepartment == 75) {
                        lessonComponent.f.form.patchValue({
                          field_type: "548"
                        });
                      } else if (studentDepartment == 77) {
                        lessonComponent.f.form.patchValue({
                          field_type: "600"
                        });
                      }
                    }
                  }
                });
              },
              error => {
                console.error('Error fetching enrollment data:', error);
                this._snackBar.open('Error fetching enrollment data', 'Close', {
                  duration: 3000,
                  horizontalPosition: 'center',
                });
              }
            );
        },
        error => {
          console.error('Error fetching student data:', error);
          this._snackBar.open('Error fetching student data', 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
          });
        }
      );
  }

  onTabChanged(event: MatTabChangeEvent) {
    // Check if we're switching to the Edit Status tab for group lessons
    if (event.tab.textLabel === 'Edit Status' &&
        this.injectedDialogData?.event?._def?.extendedProps?.appointmentType === 'group_lesson') {
      // Refresh the data
      this.updateStatusGroupLesson?.loadEventData();
    }
  }
}
