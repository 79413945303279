<!-- update-status-group-lesson.component.html -->
<form #f="ngForm">
  <ng-container *ngIf="attendees.length > 0; else noAttendeesTemplate">
    <div class="scrollable-list">
      <h3>
        Lesson: {{ groupLessonData?.date }} - {{ groupLessonData?.startTime }} to {{ groupLessonData?.endTime }}
      </h3>
      <p><strong>Instructor:</strong> {{groupLessonData?.instructor}}</p>
      <p><strong>Time:</strong> {{ groupLessonData?.startTime }} - {{ groupLessonData?.endTime }}</p>

      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Attendee</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let attendee of attendees">
            <td>{{ attendee.name }}</td>
            <td>
              <mat-form-field>
                <mat-select [(ngModel)]="attendee.status"
                            (selectionChange)="updateAttendeeStatus(attendee)"
                            name="status_{{ attendee.attendees_id }}_{{ attendee.student_id }}">
                  <mat-option *ngFor="let status of _taxonomyService.lesson_status" [value]="status.id">
                    {{status.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container mb-4">
        <div class="row">
          <div class="col-6">
            <button class="me-1" type="button"
                    [disabled]="attendees.length === 0 || isLoading"
                    (click)="setAllStatus('59')"
                    mat-raised-button color="primary">
              Set All Showed
            </button>
            <button class="me-1" type="button"
                    [disabled]="attendees.length === 0 || isLoading"
                    (click)="setAllStatus('64')"
                    mat-raised-button color="secondary">
              Set All Pending
            </button>
          </div>
          <div class="col-6">
            <div style="float:right;">
              <button type="button"
                      [disabled]="attendees.length === 0 || isLoading"
                      (click)="onSubmit(f)"
                      mat-raised-button color="accent">
                <ng-container *ngIf="!isLoading">Save</ng-container>
                <ng-container *ngIf="isLoading">
                  <mat-icon class="me-1">hourglass_top</mat-icon> Saving...
                </ng-container>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #noAttendeesTemplate>
    <div class="alert alert-info mt-2" role="alert">
      No attendees available.
    </div>
  </ng-template>

  <div *ngIf="successMessage" class="alert alert-success mt-3" role="alert">
    {{ successMessage }}
  </div>
</form>

<app-error-message [message]="this.errorMessage"></app-error-message>
