import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseComponent } from '../base-form/base-form.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-update-status-group-lesson',
  templateUrl: './update-status-group-lesson.component.html',
  styleUrls: ['./update-status-group-lesson.component.css']
})
export class UpdateStatusGroupLessonComponent extends BaseComponent implements OnInit {
  @Input() appointmentType: string;
  @Input() eventID: number;

  attendees: any[] = [];
  isLoading = false;
  groupLessonData: any = {};
  lessonStatuses: any[] = [];
  isSaving: boolean = false;
  successMessageVisible: boolean = false;

  ngOnInit() {
    this.lessonStatuses = this._taxonomyService.lesson_status;
    this.loadEventData();
  }

  loadEventData() {
    this.successMessage = '';
    this.errorMessage = '';
    this.isLoading = true;
    this._entityRESTService.getEntity('events', 'group_lesson', this.eventID)
      .subscribe(
        (data: any) => {
          // Process the dates and times
          const startDate = moment(data.field_date_and_time);
          const duration = data.field_duration?.split(':') || ['00', '45'];
          const durationMinutes = parseInt(duration[0]) * 60 + parseInt(duration[1]);
          const endDate = moment(startDate).add(durationMinutes, 'minutes');

          this.groupLessonData = {
            date: startDate.format('LL'),
            startTime: startDate.format('h:mm A'),
            endTime: endDate.format('h:mm A'),
            instructor: data.field_instructor?.name || 'Not Assigned'
          };

          // Process students
          if (data.field_students) {
            const studentsArray = Array.isArray(data.field_students)
              ? data.field_students
              : [data.field_students];

            this.attendees = studentsArray.flatMap(student => {
              const studentIds = Array.isArray(student.field_students)
                ? student.field_students
                : [student.field_students];

              return studentIds.map(studentId => ({
                attendees_id: student.id,
                name: student.title.split(', ')[studentIds.indexOf(studentId)] || student.title,
                status: student.field_status || '64',
                student_id: studentId
              }));
            });
          } else {
            this.attendees = [];
          }
          this.isLoading = false;
        },
        error => {
          console.error('Error loading group lesson data:', error);
          this.errorMessage = 'Error loading group lesson data';
          this.isLoading = false;
        }
      );
  }

  override onSubmit(form: NgForm) {
    this.isSaving = true;
    this.errorMessage = '';
    this.successMessage = '';

    // Create individual updates for each attendee
    const updates = this.attendees.map(attendee => {
      return this._entityRESTService.patchEntity(
        'attendees',
        'attendance',
        +attendee.attendees_id,
        { field_status: attendee.status }
      );
    });

    forkJoin(updates).subscribe(
      () => {
        this.isSaving = false;
        this.successMessage = 'Status updated for all attendees';
        this.loadEventData(); // Refresh data
      },
      error => {
        this.isSaving = false;
        this.errorMessage = error?.error?.message || "There was a problem updating the status";
      }
    );
  }

  setAllStatus(statusId: string): void {
    this.attendees.forEach(attendee => {
      attendee.status = statusId;
    });
  }

  isButtonDisabled(): boolean {
    return !this.attendees || this.attendees.length === 0;
  }
}
