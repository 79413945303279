<!-- weekly-enrollment-sales-tracking-report.component.html -->
<div class="container-fluid">
  <h1 class="page-header">Weekly Sales Tracking Report</h1><!-- Weekly Enrollment & Sales Tracking Report -->

	<div class="container-fluid">

		<mat-expansion-panel expanded="true">
			<mat-expansion-panel-header>
				<mat-panel-title>Filter</mat-panel-title>
			</mat-expansion-panel-header>
			<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
				<div class="row">
					<div class="col-3">
						<mat-form-field class="full-width-field">
							<mat-label>Week</mat-label>
							<mat-select [(ngModel)]="week" name="week" (selectionChange)="loadData()">
								<mat-option *ngFor="let element of WeeksForYearData" [value]="element.key">
									{{ element.value }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-3">
						<mat-form-field class="full-width-field">
							<mat-label>Year</mat-label>
							<mat-select [(ngModel)]="year" name="year" (selectionChange)="loadData()">
								<mat-option>None</mat-option>
								<mat-option *ngFor="let element of LastFourYearsData" [value]="element.key">
									{{ element.value }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</form>
		</mat-expansion-panel>


		<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

		<div class="row mt-3 mb-3">
			<div class="col-12">
				<button (click)="onPrint()" mat-flat-button color="primary">Print</button>
			</div>
		</div>
	</div>

  <div class="container-fluid">

		<!-- Inquiries Table -->
		<h2>Inquiries</h2>
		<table mat-table [dataSource]="inquiriesData" class="mat-elevation-z8 mat-table">
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef> Name </th>
				<td mat-cell *matCellDef="let row"> {{ row.name }} </td>
			</ng-container>
			<ng-container matColumnDef="callDates">
				<th mat-header-cell *matHeaderCellDef> Call Dates </th>
				<td mat-cell *matCellDef="let row"> {{ row.callDates }} </td>
			</ng-container>
			<ng-container matColumnDef="bookingDates">
				<th mat-header-cell *matHeaderCellDef> Booking Dates </th>
				<td mat-cell *matCellDef="let row"> {{ row.bookingDates }} </td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="['name', 'callDates', 'bookingDates']"></tr>
			<tr mat-row *matRowDef="let row; columns: ['name', 'callDates', 'bookingDates']"></tr>
			<tr class="mat-row" *matNoDataRow>
				<td class="mat-cell" colspan="3">No inquiries found for this period</td>
			</tr>
		</table>

	</div>

  <!-- Data Tables -->
  <div class="container-fluid">
    <!-- DE Enrollments Table -->
    <div *ngIf="deEnrollmentsData?.length > 0">
      <h2>DE Enrollments</h2>
      <table mat-table [dataSource]="deEnrollmentsData" class="mat-elevation-z8 mat-table">
        <!-- Student Name Column -->
        <ng-container matColumnDef="studentName">
          <th mat-header-cell *matHeaderCellDef>Student Name</th>
          <td mat-cell *matCellDef="let row">{{ row.student_name }}</td>
        </ng-container>
        <!-- Package Name Column -->
        <ng-container matColumnDef="packageName">
          <th mat-header-cell *matHeaderCellDef>Package</th>
          <td mat-cell *matCellDef="let row">{{ row.package_name }}</td>
        </ng-container>
        <!-- Date Enrolled Column -->
        <ng-container matColumnDef="dateEnrolled">
          <th mat-header-cell *matHeaderCellDef>Date Enrolled</th>
          <td mat-cell *matCellDef="let row">{{ row.date_enrolled | date }}</td>
        </ng-container>
        <!-- Lessons Enrolled Column -->
        <ng-container matColumnDef="lessonsEnrolled">
          <th mat-header-cell *matHeaderCellDef># of Lessons</th>
          <td mat-cell *matCellDef="let row">{{ row.lessons_enrolled }}</td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount ($)</th>
          <td mat-cell *matCellDef="let row">{{ row.amount | currency }}</td>
        </ng-container>
        <!-- Teachers Column -->
        <ng-container matColumnDef="teachers">
          <th mat-header-cell *matHeaderCellDef>Teachers</th>
          <td mat-cell *matCellDef="let row">{{ row.teachers }}</td>
        </ng-container>

        <!-- Table Headers and Rows -->
        <tr mat-header-row *matHeaderRowDef="['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
      </table>
    </div>

    <!-- Pre Enrollments Table -->
    <div *ngIf="preEnrollmentsData?.length > 0">
      <h2>Pre Enrollments</h2>
      <table mat-table [dataSource]="preEnrollmentsData" class="mat-elevation-z8 mat-table">
        <!-- Student Name Column -->
        <ng-container matColumnDef="studentName">
          <th mat-header-cell *matHeaderCellDef>Student Name</th>
          <td mat-cell *matCellDef="let row">{{ row.student_name }}</td>
        </ng-container>
        <!-- Package Name Column -->
        <ng-container matColumnDef="packageName">
          <th mat-header-cell *matHeaderCellDef>Package</th>
          <td mat-cell *matCellDef="let row">{{ row.package_name }}</td>
        </ng-container>
        <!-- Date Enrolled Column -->
        <ng-container matColumnDef="dateEnrolled">
          <th mat-header-cell *matHeaderCellDef>Date Enrolled</th>
          <td mat-cell *matCellDef="let row">{{ row.date_enrolled | date }}</td>
        </ng-container>
        <!-- Lessons Enrolled Column -->
        <ng-container matColumnDef="lessonsEnrolled">
          <th mat-header-cell *matHeaderCellDef># of Lessons</th>
          <td mat-cell *matCellDef="let row">{{ row.lessons_enrolled }}</td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount ($)</th>
          <td mat-cell *matCellDef="let row">{{ row.amount | currency }}</td>
        </ng-container>
        <!-- Teachers Column -->
        <ng-container matColumnDef="teachers">
          <th mat-header-cell *matHeaderCellDef>Teachers</th>
          <td mat-cell *matCellDef="let row">{{ row.teachers }}</td>
        </ng-container>

        <!-- Table Headers and Rows -->
        <tr mat-header-row *matHeaderRowDef="['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
      </table>
    </div>

    <!-- ORIs Table -->
    <div *ngIf="oriData?.length > 0">
      <h2>ORIs</h2>
      <table mat-table [dataSource]="oriData" class="mat-elevation-z8 mat-table">
        <!-- Student Name Column -->
        <ng-container matColumnDef="studentName">
          <th mat-header-cell *matHeaderCellDef>Student Name</th>
          <td mat-cell *matCellDef="let row">{{ row.student_name }}</td>
        </ng-container>
        <!-- Package Name Column -->
        <ng-container matColumnDef="packageName">
          <th mat-header-cell *matHeaderCellDef>Package</th>
          <td mat-cell *matCellDef="let row">{{ row.package_name }}</td>
        </ng-container>
        <!-- Date Enrolled Column -->
        <ng-container matColumnDef="dateEnrolled">
          <th mat-header-cell *matHeaderCellDef>Date Enrolled</th>
          <td mat-cell *matCellDef="let row">{{ row.date_enrolled | date }}</td>
        </ng-container>
        <!-- Lessons Enrolled Column -->
        <ng-container matColumnDef="lessonsEnrolled">
          <th mat-header-cell *matHeaderCellDef># of Lessons</th>
          <td mat-cell *matCellDef="let row">{{ row.lessons_enrolled }}</td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount ($)</th>
          <td mat-cell *matCellDef="let row">{{ row.amount | currency }}</td>
        </ng-container>
        <!-- Teachers Column -->
        <ng-container matColumnDef="teachers">
          <th mat-header-cell *matHeaderCellDef>Teachers</th>
          <td mat-cell *matCellDef="let row">{{ row.teachers }}</td>
        </ng-container>

        <!-- Table Headers and Rows -->
        <tr mat-header-row *matHeaderRowDef="['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
      </table>
    </div>

    <!-- EXTs Table -->
    <div *ngIf="extData?.length > 0">
      <h2>EXTs</h2>
      <table mat-table [dataSource]="extData" class="mat-elevation-z8 mat-table">
        <!-- Student Name Column -->
        <ng-container matColumnDef="studentName">
          <th mat-header-cell *matHeaderCellDef>Student Name</th>
          <td mat-cell *matCellDef="let row">{{ row.student_name }}</td>
        </ng-container>
        <!-- Package Name Column -->
        <ng-container matColumnDef="packageName">
          <th mat-header-cell *matHeaderCellDef>Package</th>
          <td mat-cell *matCellDef="let row">{{ row.package_name }}</td>
        </ng-container>
        <!-- Date Enrolled Column -->
        <ng-container matColumnDef="dateEnrolled">
          <th mat-header-cell *matHeaderCellDef>Date Enrolled</th>
          <td mat-cell *matCellDef="let row">{{ row.date_enrolled | date }}</td>
        </ng-container>
        <!-- Lessons Enrolled Column -->
        <ng-container matColumnDef="lessonsEnrolled">
          <th mat-header-cell *matHeaderCellDef># of Lessons</th>
          <td mat-cell *matCellDef="let row">{{ row.lessons_enrolled }}</td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount ($)</th>
          <td mat-cell *matCellDef="let row">{{ row.amount | currency }}</td>
        </ng-container>
        <!-- Teachers Column -->
        <ng-container matColumnDef="teachers">
          <th mat-header-cell *matHeaderCellDef>Teachers</th>
          <td mat-cell *matCellDef="let row">{{ row.teachers }}</td>
        </ng-container>

        <!-- Table Headers and Rows -->
        <tr mat-header-row *matHeaderRowDef="['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
      </table>
    </div>

    <!-- RENs Table -->
    <div *ngIf="renData?.length > 0">
      <h2>RENs</h2>
      <table mat-table [dataSource]="renData" class="mat-elevation-z8 mat-table">
        <!-- Student Name Column -->
        <ng-container matColumnDef="studentName">
          <th mat-header-cell *matHeaderCellDef>Student Name</th>
          <td mat-cell *matCellDef="let row">{{ row.student_name }}</td>
        </ng-container>
        <!-- Package Name Column -->
        <ng-container matColumnDef="packageName">
          <th mat-header-cell *matHeaderCellDef>Package</th>
          <td mat-cell *matCellDef="let row">{{ row.package_name }}</td>
        </ng-container>
        <!-- Date Enrolled Column -->
        <ng-container matColumnDef="dateEnrolled">
          <th mat-header-cell *matHeaderCellDef>Date Enrolled</th>
          <td mat-cell *matCellDef="let row">{{ row.date_enrolled | date }}</td>
        </ng-container>
        <!-- Lessons Enrolled Column -->
        <ng-container matColumnDef="lessonsEnrolled">
          <th mat-header-cell *matHeaderCellDef># of Lessons</th>
          <td mat-cell *matCellDef="let row">{{ row.lessons_enrolled }}</td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount ($)</th>
          <td mat-cell *matCellDef="let row">{{ row.amount | currency }}</td>
        </ng-container>
        <!-- Teachers Column -->
        <ng-container matColumnDef="teachers">
          <th mat-header-cell *matHeaderCellDef>Teachers</th>
          <td mat-cell *matCellDef="let row">{{ row.teachers }}</td>
        </ng-container>

        <!-- Table Headers and Rows -->
        <tr mat-header-row *matHeaderRowDef="['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
      </table>
    </div>

    <!-- Non-Unit Sales Table -->
    <div *ngIf="nonUnitSalesData?.length > 0">
      <h2>Non-Unit Sales</h2>
      <table mat-table [dataSource]="nonUnitSalesData" class="mat-elevation-z8 mat-table">
        <!-- Student Name Column -->
        <ng-container matColumnDef="studentName">
          <th mat-header-cell *matHeaderCellDef>Student Name</th>
          <td mat-cell *matCellDef="let row">{{ row.student_name }}</td>
        </ng-container>
        <!-- Package Name Column -->
        <ng-container matColumnDef="packageName">
          <th mat-header-cell *matHeaderCellDef>Package</th>
          <td mat-cell *matCellDef="let row">{{ row.package_name }}</td>
        </ng-container>
        <!-- Date Enrolled Column -->
        <ng-container matColumnDef="dateEnrolled">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let row">{{ row.date_enrolled | date }}</td>
        </ng-container>
        <!-- Quantity Column -->
        <ng-container matColumnDef="lessonsEnrolled">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let row">{{ row.lessons_enrolled }}</td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount ($)</th>
          <td mat-cell *matCellDef="let row">{{ row.amount | currency }}</td>
        </ng-container>
        <!-- Teachers Column -->
        <ng-container matColumnDef="teachers">
          <th mat-header-cell *matHeaderCellDef>Teachers</th>
          <td mat-cell *matCellDef="let row">{{ row.teachers }}</td>
        </ng-container>

        <!-- Table Headers and Rows -->
        <tr mat-header-row *matHeaderRowDef="['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
      </table>
    </div>

    <!-- Miscellaneous Sales Table -->
    <div *ngIf="miscSalesData?.length > 0">
      <h2>Miscellaneous Sales</h2>
      <table mat-table [dataSource]="miscSalesData" class="mat-elevation-z8 mat-table">
        <!-- Student Name Column -->
        <ng-container matColumnDef="studentName">
          <th mat-header-cell *matHeaderCellDef>Customer Name</th>
          <td mat-cell *matCellDef="let row">{{ row.student_name }}</td>
        </ng-container>
        <!-- Package Name Column -->
        <ng-container matColumnDef="packageName">
          <th mat-header-cell *matHeaderCellDef>Package</th>
          <td mat-cell *matCellDef="let row">{{ row.package_name }}</td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="dateEnrolled">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let row">{{ row.date_enrolled | date }}</td>
        </ng-container>
        <!-- Quantity Column -->
        <ng-container matColumnDef="lessonsEnrolled">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let row">{{ row.lessons_enrolled }}</td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount ($)</th>
          <td mat-cell *matCellDef="let row">{{ row.amount | currency }}</td>
        </ng-container>
        <!-- Teachers Column -->
        <ng-container matColumnDef="teachers">
          <th mat-header-cell *matHeaderCellDef>Teachers</th>
          <td mat-cell *matCellDef="let row">{{ row.teachers }}</td>
        </ng-container>

        <!-- Table Headers and Rows -->
        <tr mat-header-row *matHeaderRowDef="['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['studentName', 'packageName', 'dateEnrolled', 'lessonsEnrolled', 'amount', 'teachers']"></tr>
      </table>
    </div>

	</div>

	<!-- Spacing for the bottom of the page -->
	<div class="mt-4" style="height: 100px;"></div>

</div>
