import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tutorial-single',
  templateUrl: './tutorial-single.component.html',
  styleUrls: ['./tutorial-single.component.css']
})
export class TutorialSingleComponent implements OnInit {
  @Input() tutorialData: any;
  safeUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    const urlParams = '?skipCover=true&defaultListView=false&skipBranding=false&makeViewOnly=true&hideAuthorAndDetails=true';
    const fullUrl = `${this.tutorialData.iframeSrc}${urlParams}`;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
  }
}
