<!-- student-dashboard.wrapper.component.html -->
<app-entity-mat-dialog-title title="Student Dashboard" [titleSuffix]="studentDashboardData?.['#studentName']"></app-entity-mat-dialog-title>

<div [ngClass]="{'loading-shade': showProgressSpinner}">
  <div *ngIf="!showProgressSpinner">

		<div mat-dialog-content style="min-height:400px;">

			<!-- Student Summary  -->
			<div *ngIf="studentDashboardData" id="student-summary" class="row justify-content-center">
				<h3 class="text-center"><b>{{ studentDashboardData['#studentName'] }}</b>
					Summary</h3>
				<div class="col-2 text-center">
					<p style="border: 12px solid #68ff94;" class="summary-hero">
						{{ studentDashboardData['#totalLessonsEnrolled'] }}
					</p>
					<div>Total Lessons Enrolled</div>
				</div>

				<div class="col-2 text-center">
					<p style="border: 12px solid #68ff94;" class="summary-hero">
						{{ studentDashboardData['#totalLessonsTaken'] }}
					</p>
					<div>Total Lessons Taken</div>
				</div>

				<div class="col-2 text-center">
					<p style="border: 12px solid #FCC761;" class="summary-hero">
						{{ studentDashboardData['#totalLessonsRemaining'] }}
					</p>
					<div>Total Lessons Remaining</div>
				</div>

				<div class="col-2 text-center">
					<p style="border: 12px solid #E5E5E1;" class="summary-hero">
						{{ studentDashboardData['#totalLessonsPaidAhead'] ? studentDashboardData['#totalLessonsPaidAhead']
						: '0' }}
					</p>
					<div>Total Lessons Paid Ahead</div>
				</div>

				<div class="col-2 text-center">
					<p style="border: 12px solid #0077B7;" class="summary-hero">
						{{ studentDashboardData['#totalFutureLessons'] }}
					</p>
					<div>Total Future Lessons</div>
				</div>
			</div>

			<div *ngIf="studentDashboardData" class="row text-center mt-4">
				<!-- <div class="col-md-3">
					<strong>Current Enrollment: </strong>
					<span>#{{ currentEnrollment?.title?.[0]?.value }}</span>
				</div> -->
				<div class="col-md-3" style="white-space: nowrap;">
					<strong>Enrollment Total:</strong> ${{ enrollmentBalance?.unused_lesson_value || '0' }}
					<strong>Available: </strong> ${{ enrollmentBalance?.remaining_amount || '0' }}
				</div>
				<div class="col-md-6">
					<strong>Overall Balance Due: </strong>
					<span>${{ overallEnrollmentBalance?.total_balance_due }}</span>

					<strong id="paymentDue">/ Payment Due: </strong>
					<ng-container *ngIf="overallEnrollmentBalance?.next_payment_due_date; else noNextPayment">
						<span>${{ overallEnrollmentBalance?.next_payment_amount_due }} ({{ overallEnrollmentBalance?.next_payment_due_date | date:'MMM d, y' }})</span>
					</ng-container>
					<ng-template #noNextPayment>
						<span>No Next Payment</span>
					</ng-template>

				</div>
				<div class="col-md-3">
					<strong>Next Lesson Scheduled: </strong>
					<span>{{ formatNextLesson(nextLessonScheduled) }}</span>
				</div>
			</div>

			<div class="container">
				<div class="row">
					<div class="col-5 justify-content-center">
						<!-- Student Basic/Inquiry Info -->
						<mat-card *ngIf="studentDashboardData" id="student-basic-info"
							style="height:260px;">
							<mat-card-title>
								Student Information
							</mat-card-title>
							<mat-card-content style="height:119px;">
								<!-- Student and Partner Information Display -->
								<div class="">
									<div class="row" style="min-height: 120px;">
										<!-- Student Information -->
										<div class="col-6">
												<p><strong>{{studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['name']}} {{studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['lastName']}}</strong><br/>
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['cell'] ? (studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['cell'] | phone) : 'Cell: N/A' }}<br/>
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['email'] || 'Email: N/A' }}
												</p>
												<span style="width: 100%; clear: both; display: block;" class="mt-2">
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['address_line1'] || 'Address: N/A' }}<br/>
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['locality'] || '' }}
														<span *ngIf="studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['locality'] && studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['administrative_area']">,</span>
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['administrative_area'] || '' }}
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['address']?.['postal_code'] || '' }}
												</span>

												<!-- <button
														class="me-1 mt-2 addLesson"
														(click)="openEntityComponent(StudentRecordEntityComponent, 'contacts', 'student_record', 'edit', studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['contact_id'])"
														mat-flat-button>
														Edit User
												</button> -->
										</div>

										<!-- Partner Information -->
										<div class="col-6" style="border-left: 1px solid black;" *ngIf="studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['name']">
												<p><strong>{{studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['name']}} {{studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['lastName']}}</strong><br/>
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['cell'] ? (studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['cell'] | phone) : 'Cell: N/A' }}<br/>
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['email'] || 'Email: N/A' }}
												</p>
												<span style="width: 100%; clear: both; display: block;" class="mt-2">
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['address_line1'] || 'Address: N/A' }}<br/>
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['locality'] || '' }}
														<span *ngIf="studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['locality'] && studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['administrative_area']">,</span>
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['administrative_area'] || '' }}
														{{ studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['address']?.['postal_code'] || '' }}
												</span>
										</div>
									</div>
								</div>
							</mat-card-content>
							<mat-card-actions>

								<div class="">
									<div class="row">
										<div class="col-6">
											<button
												*ngIf="studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['contact_id']"
												class="me-1 addLesson"
												(click)="openEntityComponent(StudentRecordEntityComponent, 'contacts', 'student_record', 'edit', studentDashboardData?.['#studentBasicInfoWithPartner']?.['student']?.['contact_id'])"
												mat-flat-button>
												Edit User
											</button>
										</div>
										<div class="col-6">
											<button
												*ngIf="studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['contact_id']"
												class="me-1 addLesson"
												(click)="openEntityComponent(StudentRecordEntityComponent, 'contacts', 'student_record', 'edit', studentDashboardData?.['#studentBasicInfoWithPartner']?.['partner']?.['contact_id'])"
												mat-flat-button>
												Edit Partner
											</button>
										</div>
									</div>
								</div>


								<!-- <button class="me-1" mat-raised-button (click)="this._dialogService.openDialog(InquiryComponent)" color="primary"
																mat-button>Edit User</button> -->

								<!-- <button
									class="me-1 addLesson"
									(click)="openEntityComponent(StudentInquiryDetailsComponent, 'student_accounts', 'student_account', 'edit', studentAccountID)"
									mat-flat-button>
									Edit Student Account
								</button> -->

								<!-- <button
																class="me-1"
																mat-raised-button color="primary"
																(click)="openDialog(StudentDepartmentComponent, {
																		height: 'auto',
																		width: '600px',
																		data: {
																				student_id: this.user.id
																		}
														});">Department</button> -->

								<!-- <button
																class="me-1"
																mat-raised-button color="primary"
																(click)="openDialog(SetOpeningBalanceComponent, {
																		height: 'auto',
																		width: '600px',
																		data: {
																				student_id: this.user.id
																		}
														});">Set Opening Balance</button> -->

								<!-- <button
									class="me-1"
									mat-raised-button color="primary"
									(click)="openDialog(PaidLessonAuditComponent, {
																		height: 'auto',
																		width: '600px',
																		data: {
																				student_id: this.user.id
																		}
														});">Audit</button> -->

								<!-- <button
														class="me-1"
														mat-raised-button color="primary"
														(click)="openDialog(SendUpdateAPIComponent, {
																height: 'auto',
																width: '600px',
																data: {
																		operation: 'asd'
																}
														});">Send API</button> -->

								<!-- <button class="me-1" (click)="openDialog(LessonComponent)" color="primary" mat-button>Department</button> -->
								<!-- <button class="me-1" (click)="openDialog(LessonComponent)" color="primary" mat-button>Set Opening Balance</button> -->

							</mat-card-actions>
						</mat-card>
					</div>
					<div class="col-2 image-container" style="padding-top:80px;">
						<div *ngIf="studentBadgeInfo?.['status']">
						<img *ngIf="studentBadgeInfo?.['status'] === 'Active'; else inactiveImage"
								 class="img-fluid"
								 src="assets/images/ActiveShield-Blank2.jpg"
								 alt="Active Shield" />
						<ng-template #inactiveImage>
							<img class="img-fluid"
									 src="assets/images/InactiveShield-Blank.jpg"
									 alt="Inactive Shield" />
						</ng-template>
						<div class="overlay-text top-overlay">{{studentBadgeInfo?.['department']}}</div>
						<div class="overlay-text bottom-overlay">{{studentBadgeInfo?.['status']}}</div>
						</div>
					</div>

					<div class="col-5 justify-content-center">
						<!-- Student Basic/Inquiry Info -->
						<mat-card style="height:260px;" *ngIf="studentDashboardData" class>
							<mat-card-title>
								Inquiry Details
							</mat-card-title>
							<mat-card-content style="height:119px;">
								<table style="height: 132px;" class="table table-responsive" *ngIf="studentDashboardData">
									<tr>
										<th class="text-nowrap">Inquired</th>
										<th class="text-nowrap">Booked</th>
										<th class="text-nowrap">Showed</th>
										<th class="text-nowrap">Inq. Method</th>
										<th class="text-nowrap">Inq. Taker</th>
									</tr>
									<tr>
										<td class="text-nowrap">
											{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquired'] == '') ? 'Not inquired.' : '' }}
											{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquired'] != '' ? studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquired'] : '') | date:'MM/dd/yy' }}
										</td>
										<td class="text-nowrap">
												{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['bookedOn'] == '') ? 'Not yet booked.' : '' }}
												{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['bookedOn'] != '' ? studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['bookedOn'] : '') | date:'MM/dd/yy' }}
										</td>
										<td class="text-nowrap">
												{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['showed'] == '') ? 'Not showed yet.' : '' }}
												{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['showed'] != '' ? studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['showed'] : '') | date:'MM/dd/yy' }}
										</td>
										<td class="text-nowrap">
											{{ studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquiryMethod'] }}
										</td>
										<td class="text-nowrap">
											{{ studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquiryTaker'] }}
										</td>
									</tr>
									<tr>
										<th colspan="5">Notes</th>
									</tr>
									<tr>
										<td colspan="5">
											<div style="height: 40px;
											width: 440px;
											padding: 1px;
											margin-bottom: 0px;
											padding-bottom: 0px;
											overflow-y: scroll;">
											{{ studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['notes'] | truncate:500:true }}
											</div>
										</td>
									</tr>
								</table>
								<button
									class="me-1 addLesson"
									mat-flat-button
									(click)="openEntityComponent(StudentInquiryDetailsComponent, 'student_accounts', 'student_account', 'edit', studentAccountID)">
									Edit Inquiry Information
								</button>
							</mat-card-content>
						</mat-card>

					</div>
				</div>

			</div>

			<div class="container">
				<mat-card>
					<mat-card-title>
						Upcoming Lessons
					</mat-card-title>
					<app-student-future-lessons [drupal_student_id]="this.user.id"></app-student-future-lessons>
				</mat-card>
			</div>

			<div *ngIf="studentDashboardObservable | async" class="container-fluid mt-4" style="min-height:600px;">
				<div class="row">
					<!-- Tab labels (vertical) -->
					<div class="col-2 vertical-tab-labels">
						<div class="nav flex-column nav-pills studentDashboardInfo" role="tablist"
							aria-orientation="vertical">
							<a class="nav-link" [class.active]="selectedTab === 0"
								(click)="selectedTab = 0">Lesson List</a>
							<a class="nav-link" [class.active]="selectedTab === 1"
								(click)="selectedTab = 1">Lesson Enrollments</a>
							<a class="nav-link" [class.active]="selectedTab === 2"
								(click)="selectedTab = 2">Payments</a>
							<a class="nav-link" [class.active]="selectedTab === 3"
									(click)="selectedTab = 3">Miscellaneous</a>
							<a class="nav-link" [class.active]="selectedTab === 4"
									(click)="selectedTab = 4">Sundry</a>
							<a class="nav-link" [class.active]="selectedTab === 5"
									(click)="selectedTab = 5">Non Unit Lessons</a>
							<a class="nav-link" [class.active]="selectedTab === 6"
									(click)="selectedTab = 6">Non Unit Enrollments</a>
							<a class="nav-link" [class.active]="selectedTab === 7"
									(click)="selectedTab = 7">Service List</a>
						</div>
					</div>

					<!-- Tab content -->
					<div class="col-10">
						<div [hidden]="selectedTab !== 0">
							<div class>
								<div class="col-md-12 mt-3">
									<div class="float-end pb-3">
										<button class="addLesson" mat-flat-button
											(click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create', null, {
																					'__field_students_inline_form': {
																							'type': 'attendees',
																							'bundle': 'attendance',
																							'field_student_account': studentDashboardData['#studentName'] + ' (' + studentAccountID + ')'
																					}
																			})">
											Add Lesson
										</button>
									</div>
								</div>
							</div>

							<table class="mb-3" mat-table matSort [dataSource]="lessonList2Rows">

								<!-- Date  -->
								<ng-container matColumnDef="date">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
									<td mat-cell *matCellDef="let element">
										<span class="text-nowrap">
											{{element.date_and_time | date:'medium'}}
										</span>
									</td>
								</ng-container>

								<!-- Service  -->
								<ng-container matColumnDef="service">
									<th mat-header-cell *matHeaderCellDef>Service</th>
									<td mat-cell *matCellDef="let element">
										{{ element.service }}
									</td>
								</ng-container>

								<!-- Length/Duration  -->
								<ng-container matColumnDef="length">
									<th mat-header-cell *matHeaderCellDef>Length</th>
									<td mat-cell *matCellDef="let element">
										{{ element.duration }}
									</td>
								</ng-container>

								<!-- Teacher  -->
								<ng-container matColumnDef="teacher">
									<th mat-header-cell *matHeaderCellDef>Teacher</th>
									<td mat-cell *matCellDef="let element">
										{{ element.teacher }}
									</td>
								</ng-container>

								<!-- Contacts Present  -->
								<ng-container matColumnDef="contacts_present">
									<th mat-header-cell *matHeaderCellDef>Contacts Present</th>
									<td mat-cell *matCellDef="let element">
										{{element.contacts_present}}
									</td>
								</ng-container>

								<!-- Status  -->
								<ng-container matColumnDef="status">
									<th mat-header-cell *matHeaderCellDef>Status</th>
									<td mat-cell *matCellDef="let element">
										<div *ngIf="this._permissionsService.has_access('view_admin_links')">
											<div *ngIf="element.bundle !== 'enrollment'" class="dropdown">
												<button class="btn btn-sm dropdown-toggle status-badge" [ngClass]="getStatusClass(element.status)" type="button" id="statusDropdown{{element.id}}" data-bs-toggle="dropdown" aria-expanded="false">
													<span *ngIf="!element.isUpdating">{{ getStatusText(element.status) }}</span>
													<span *ngIf="element.isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
												</button>
												<ul class="dropdown-menu" aria-labelledby="statusDropdown{{element.id}}">
													<li *ngFor="let status of lessonStatuses">
														<a class="dropdown-item cursor-pointer" (click)="updateStatus(element.id, status.id)">{{ status.name }}</a>
													</li>
												</ul>
											</div>
											<!-- <span *ngIf="element.bundle !== 'enrollment' && !element.isUpdating" class="amt-bg-red badge">{{ element.status === '' ? 'Pending Status' : '' }}</span> -->
										</div>
										<div *ngIf="!this._permissionsService.has_access('view_admin_links')">
											<span class="amt-bg-gray badge">{{ element.status == '63' ? 'Cancelled' : '' }}</span>
											<span class="amt-bg-gray badge">{{ element.status == '61' ? 'No Showed Not Charged' : '' }}</span>
											<span class="amt-bg-orange badge">{{ element.status == '60' ? 'No Showed, Charged' : '' }}</span>
											<span class="amt-bg-red badge">{{ element.status == '64' ? 'Pending Status' : '' }}</span>
											<span class="amt-bg-red badge">{{ element.status == '' ? '' : '' }}</span>
											<span class="amt-bg-purple badge">{{ element.status == '62' ? 'Rescheduled' : '' }}</span>
											<span class="amt-bg-green badge">{{ element.status == '59' ? 'Showed' : '' }}</span>
											<span class="amt-bg-red badge">{{ element.status == '' && element.bundle != 'enrollment' ? 'Pending Status' : '' }}</span>
										</div>
									</td>
								</ng-container>

								<!-- Taken / Rem  -->
								<ng-container matColumnDef="taken / rem">
									<th mat-header-cell *matHeaderCellDef>Taken / Rem</th>
									<td mat-cell *matCellDef="let element">
										<ng-container *ngIf="element.bundle !== 'group_lesson'; else noLessonCounts">
											{{ element.taken }}<span *ngIf="element.taken !== '' && element.remaining !== ''"> / </span>{{ element.remaining }}
										</ng-container>
										<ng-template #noLessonCounts></ng-template>
									</td>
								</ng-container>

								<!-- Edit  -->
								<ng-container matColumnDef="edit">
									<th mat-header-cell *matHeaderCellDef>Edit</th>
									<td mat-cell *matCellDef="let element">
										<mat-icon
											class="cursor-pointer"
											*ngIf="element.bundle == 'lesson'"
											(click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lesson', 'edit', element.id)">
											edit_square
										</mat-icon>
									</td>
								</ng-container>

								<!-- Row shown when there is no matching data. -->
								<tr class="mat-row" *matNoDataRow>
									<td class="mat-cell" colspan="4">No lessons taken.</td>
								</tr>

								<tr mat-header-row *matHeaderRowDef="lessonListColumns"></tr>
								<tr [ngClass]="{'bg-success': element.bundle === 'enrollment'}" mat-row *matRowDef="let element; columns: lessonListColumns;"></tr>

							</table>

							<!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
						</div>
						<div [hidden]="selectedTab !== 1">
							<!-- Content for Lesson Enrollments -->
							<app-lesson-student-report
								[drupal_student_name]="studentDashboardData['#studentName']"
								[enrollmentsToLoad]="'DE,Bonus/Comp Lesson,Extension,Pre-Original,Original,Renewals,Transfer In,Uncategorized,History'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</div>
						<div [hidden]="selectedTab !== 2">
							<!-- Content for Payments -->
							<app-payment-reports
								[drupal_student_name]="studentDashboardData['#studentName']"
								[drupal_account_id]="studentAccountID" [drupal_student_id]="this.user.id"></app-payment-reports>

						</div>
						<div [hidden]="selectedTab !== 3">
							<!-- Content for Miscellaneous -->
							<app-lesson-student-report
							[drupal_student_name]="studentDashboardData['#studentName']"
							[enrollmentsToLoad]="'Miscellaneous'"
							[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>

						</div>

						<div [hidden]="selectedTab !== 4">
							<!-- Content for Sundry -->
							<app-lesson-student-report
								[drupal_student_name]="studentDashboardData['#studentName']"
								[enrollmentsToLoad]="'Sundry'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>

						</div>

						<div [hidden]="selectedTab !== 5">
							<!-- Content for Non Unit Lessons -->
							<app-non-unit-lessons
							[drupal_studentAccount_id]="studentAccountID"></app-non-unit-lessons>

						</div>

						<div [hidden]="selectedTab !== 6">
							<!-- Content for Non Unit Enrollments -->
							<div class="mt-3">
								<strong>Master Class Enrolled:</strong> {{ studentDashboardData['#totalMasterclassEnrolled'] }} •
								<strong>Master Class Taken:</strong> {{ studentDashboardData['#totalMasterclassTaken'] }} •
								<strong>Coaching Enrolled:</strong> {{ studentDashboardData['#totalCoachingEnrolled'] }} •
								<strong>Coaching Taken:</strong> {{ studentDashboardData['#totalCoachingTaken'] }}
							</div>
							<app-lesson-student-report
								[drupal_student_name]="studentDashboardData['#studentName']"
								[enrollmentsToLoad]="'Non-Unit'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</div>

						<div [hidden]="selectedTab !== 7">
							<!-- Content for Service List -->
							<div class>
								<div class="col-md-12 pt-3 pb-3">
									<div class="float-end">
										<button class="addService" mat-flat-button
											(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create', null, {
																					'__field_students_inline_form': {
																							'type': 'attendees',
																							'bundle': 'attendance',
																							'field_student_account': studentDashboardData['#studentName'] + ' (' + studentAccountID + ')'
																					}
																			})">
											Add Service
										</button>
									</div>
								</div>
							</div>

							<!-- TABLE  -->
							<table *ngIf="studentServicesRows" class="" mat-table
								[dataSource]="studentServicesRows">

								<!-- Date  -->
								<ng-container matColumnDef="date">
									<th mat-header-cell *matHeaderCellDef>Date</th>
									<td mat-cell *matCellDef="let element">{{ element[0].data | date:'medium' }}</td>
								</ng-container>

								<!-- Time  -->
								<ng-container matColumnDef="time">
									<th mat-header-cell *matHeaderCellDef>Time</th>
									<td mat-cell *matCellDef="let element">{{ element[1].data }}</td>
								</ng-container>

								<!-- Service  -->
								<ng-container matColumnDef="service">
									<th mat-header-cell *matHeaderCellDef>Service</th>
									<td mat-cell *matCellDef="let element">{{ element[2] }}</td>
								</ng-container>

								<!-- Length  -->
								<ng-container matColumnDef="length">
									<th mat-header-cell *matHeaderCellDef>Length</th>
									<td mat-cell *matCellDef="let element">{{ element[3] }}</td>
								</ng-container>

								<!-- Executive  -->
								<ng-container matColumnDef="teacher">
									<th mat-header-cell *matHeaderCellDef>Executive</th>
									<td mat-cell *matCellDef="let element">{{ element[4] }}</td>
								</ng-container>

								<!-- Status  -->
								<ng-container matColumnDef="status">
									<th mat-header-cell *matHeaderCellDef>Status</th>
									<td mat-cell *matCellDef="let element">
										<span class="badge" [ngClass]="{
											'amt-bg-gray': (element[5]?.data === 'Cancelled' || element[5]?.data === 'No Showed'),
											'amt-bg-orange': (element[5]?.data === 'Incomplete'),
											'amt-bg-red': (element[5]?.data === 'Pending Status' || element[5]?.data === 'No Sale'),
											'amt-bg-purple': (element[5]?.data === 'Rescheduled'),
											'amt-bg-green': (element[5]?.data === 'Sale'),
											'amt-bg-blue': (element[5]?.data === 'Think It Over')
									}">{{ element[5]?.data }}</span>
									</td>
								</ng-container>

								<!-- Edit  -->
								<ng-container matColumnDef="edit">
									<th mat-header-cell *matHeaderCellDef>Edit</th>
									<td mat-cell *matCellDef="let element">
										<mat-icon
											class="cursor-pointer"
											(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', element.id)">
											edit_square
										</mat-icon>
									</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="studentServicesColumns"></tr>
								<tr mat-row *matRowDef="let row; columns:
															studentServicesColumns;"></tr>
							</table>





						</div>
					</div>
				</div>
			</div>

			<div *ngIf="false && studentDashboardObservable | async" class="container-fluid mt-4" style="min-height:600px;">
				<mat-tab-group
					animationDuration="0ms">

					<mat-tab label="Lesson List">

					</mat-tab>

					<mat-tab label="Lesson Enrollments">
						<ng-template matTabContent>
							<app-lesson-student-report
								[drupal_student_name]="studentDashboardData['#studentName']"
								[drupal_account_id]="studentAccountID"
								[enrollmentsToLoad]="'DE,Bonus/Comp Lesson,Extension,Pre-Original,Original,Renewals,Transfer In,Uncategorized'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</ng-template>
					</mat-tab>

					<mat-tab label="Payments">
						<ng-template matTabContent>
							<app-payment-reports
								[drupal_student_name]="studentDashboardData['#studentName']"
								[drupal_account_id]="studentAccountID" [drupal_student_id]="this.user.id"></app-payment-reports>
						</ng-template>
					</mat-tab>

					<mat-tab label="Miscellaneous">
						<ng-template matTabContent>
							<!-- <app-misc-student-report [drupal_studentAccount_id]="studentAccountID"></app-misc-student-report> -->
							<app-lesson-student-report
								[drupal_student_name]="studentDashboardData['#studentName']"
								[drupal_account_id]="studentAccountID"
								[enrollmentsToLoad]="'Miscellaneous'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</ng-template>
					</mat-tab>

					<mat-tab label="Sundry">
						<ng-template matTabContent>
							<!-- <app-sundry-student-report [drupal_studentAccount_id]="studentAccountID"></app-sundry-student-report> -->
							<app-lesson-student-report
								[drupal_student_name]="studentDashboardData['#studentName']"
								[drupal_account_id]="studentAccountID" [enrollmentsToLoad]="'Sundry'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</ng-template>
					</mat-tab>

					<mat-tab label="Non Unit Lessons">
						<ng-template matTabContent>
							<app-non-unit-lessons
								[drupal_student_name]="studentDashboardData['#studentName']"
								[drupal_account_id]="studentAccountID"
								[drupal_studentAccount_id]="studentAccountID"></app-non-unit-lessons>
						</ng-template>
					</mat-tab>

					<mat-tab label="Non Unit Enrollments">
						<ng-template matTabContent>
							<div class="mt-3">
								<strong>Master Class Enrolled:</strong> {{ studentDashboardData['#totalMasterclassEnrolled']
								}} •
								<strong>Master Class Taken:</strong> {{ studentDashboardData['#totalMasterclassTaken']
								}} •
								<strong>Coaching Enrolled:</strong> {{ studentDashboardData['#totalCoachingEnrolled']
								}} •
								<strong>Coaching Taken:</strong> {{ studentDashboardData['#totalCoachingTaken']
								}}
							</div>
							<app-lesson-student-report
								[drupal_student_name]="studentDashboardData['#studentName']"
								[drupal_account_id]="studentAccountID" [enrollmentsToLoad]="'Non-Unit'"
								[drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
						</ng-template>
					</mat-tab>

					<mat-tab label="Service List">

						<div class>
							<div class="col-md-12 pt-3 pb-3">
								<div class="float-end">
									<button class="addService" mat-flat-button
										(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create', null, {
																				'__field_students_inline_form': {
																						'type': 'attendees',
																						'bundle': 'attendance',
																						'field_student_account': studentDashboardData['#studentName'] + ' (' + studentAccountID + ')'
																				}
																		})">
										Add Service
									</button>
								</div>
							</div>
						</div>

						<!-- TABLE  -->
						<table *ngIf="studentServicesRows" class="table" mat-table
							[dataSource]="studentServicesRows">

							<!-- Date  -->
							<ng-container matColumnDef="date">
								<th mat-header-cell *matHeaderCellDef>Date</th>
								<td mat-cell *matCellDef="let element">{{ element[0].data }}</td>
							</ng-container>

							<!-- Time  -->
							<ng-container matColumnDef="time">
								<th mat-header-cell *matHeaderCellDef>Time</th>
								<td mat-cell *matCellDef="let element">{{ element[1].data }}</td>
							</ng-container>

							<!-- Service  -->
							<ng-container matColumnDef="service">
								<th mat-header-cell *matHeaderCellDef>Service</th>
								<td mat-cell *matCellDef="let element">{{ element[2] }}</td>
							</ng-container>

							<!-- Length  -->
							<ng-container matColumnDef="length">
								<th mat-header-cell *matHeaderCellDef>Length</th>
								<td mat-cell *matCellDef="let element">{{ element[3] }}</td>
							</ng-container>

							<!-- Teacher  -->
							<ng-container matColumnDef="teacher">
								<th mat-header-cell *matHeaderCellDef>Teacher</th>
								<td mat-cell *matCellDef="let element">{{ element[4] }}</td>
							</ng-container>

							<!-- Status  -->
							<ng-container matColumnDef="status">
								<th mat-header-cell *matHeaderCellDef>Status</th>
								<td mat-cell *matCellDef="let element"><span [ngClass]="{
																		'amt-bg-red': element[5].style,
																		'badge' : element[5].style
																		}">{{ element[5].data }}</span></td>
							</ng-container>

							<!-- Edit  -->
							<ng-container matColumnDef="edit">
								<th mat-header-cell *matHeaderCellDef>Edit</th>
								<td mat-cell *matCellDef="let element">
									<mat-icon
										class="cursor-pointer"
										(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', element.id)">
										edit_square
									</mat-icon>
									<!-- <button mat-flat-button color="accent"
																		(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', element.id)">
																		Edit Service
																</button> -->
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="studentServicesColumns"></tr>
							<tr mat-row *matRowDef="let row; columns:
														studentServicesColumns;"></tr>
						</table>

						<!-- <app-generic-table [Columns]="studentServices['#header']" [Rows]="studentServices['#rows']"></app-generic-table> -->
					</mat-tab>

				</mat-tab-group>
			</div>

		</div>
	</div>
</div>
