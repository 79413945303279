import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, FormControl, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';
import { MatStepper } from '@angular/material/stepper';
import _, { map } from 'lodash';
import moment from 'moment';
import { EventLessonEntityComponent } from '../event-lesson-entity/event-lesson-entity.component';
import { environment } from 'src/environments/environment';
import { BaseEnrollmentComponent } from '../../enrollment/base-enrollment/base-enrollment.component';
import { HttpParams } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-enrollment-entity',
  templateUrl: './enrollment-entity.component.html',
  styleUrls: ['./enrollment-entity.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EnrollmentEntityComponent extends BaseEnrollmentComponent implements OnInit {

  override eckType = "packages";
  override bundle = "enrollment";
  override entityId = this._injectedDialogData['data']['EntityID'];

  field_enrollment_name: any;
  scheduledPayment_list = [];
  scheduledPaymentEditMode: boolean;
  scheduledPaymentEditIndex: number;
  // executives = [];
  // executiveEditMode: boolean;
  instructor_percentage_list = [];
  instructorPercentageEditMode: boolean;
  instructorPercentageEditIndex: number;

  is_pay_in_full: boolean;
  is_use_installments: boolean;
  override interval: number = 1;

  discountTotal;
  displayDiscountTotalString;

  eventCount;
  lessonCountDisabled: boolean;
  isMiscOrSundry: boolean = false;
  studioAddress: any;
  studioPhone: any;
  studioEmail: any;
  studioWebsite: any;
  studioName: any;
  console: any;
  taxConfig: any[] = [];
  studentBalanceWarning: string;

  giftCertificateAmount: number = 0;
  studentBalanceData: any;
  // giftCertificatesArray: FormArray;

  addGiftCertificate() {
    this.giftCertificatesArray.push(this.createGiftCertificateGroup());
  }

  createGiftCertificateGroup(): FormGroup {
    return this._formBuilder.group({
      code: [''],
      redemptionAmount: [null],
      applied: [false] // Add the 'applied' form control
    });
  }

  removeGiftCertificate(index: number) {
    this.giftCertificatesArray.removeAt(index);
  }

  get giftCertificatesArray() {
    return this.enrollmentForm.get('giftCertificates') as FormArray;
  }

  applyGiftCertificate(index: number) {
    const giftCertificate = this.giftCertificatesArray.at(index);
    const code = giftCertificate.get('code').value;
    const redemptionAmount = giftCertificate.get('redemptionAmount').value;

    this._drupalRESTService.httpGET(`/api_rest/v1/getGiftCertificateEntityFromCode?code=${code}`).subscribe(
      (response: any) => {
        if (response.error) {
          // Display an error message if the gift certificate is not found
          this._snackBar.open('Gift certificate not found', 'Close', {
            duration: 3000,
          });
        } else if (response.status === 'active') {
          const remainingBalance = parseFloat(response.amount);

          if (redemptionAmount <= remainingBalance) {
            // Update the gift certificate's remaining balance
            const updatedRemainingBalance = remainingBalance - redemptionAmount;
            this._drupalRESTService.httpPATCH(
              `/api_rest/v1/updateGiftCertificateRemainingBalance`,
              { code: code, remaining_balance: updatedRemainingBalance }
            ).subscribe(
              (updateResponse: any) => {
                // Handle the update response if needed
              },
              (updateError) => {
                console.error('Error updating gift certificate remaining balance:', updateError);
              }
            );

            // Update the total gift certificate amount
            this.giftCertificateAmount += redemptionAmount;
            giftCertificate.patchValue({ applied: true });
            this.calcNewTotal();
          } else {
            // Display an error message if the redemption amount exceeds the remaining balance
            this._snackBar.open('Redemption amount exceeds gift certificate balance', 'Close', {
              duration: 3000,
            });
          }
        } else {
          // Display an error message if the gift certificate is not active
          this._snackBar.open('Gift certificate is not active', 'Close', {
            duration: 3000,
          });
        }
      },
      (error) => {
        console.error('Error loading gift certificate:', error);
        this._snackBar.open('An error occurred while loading the gift certificate', 'Close', {
          duration: 3000,
        });
      }
    );
  }

  onAutoCompleteStudentLoadInstructorExecutive($event) {
    const studentId = this.regexStudentName($event.option.value);

    this._entityRESTService.getEntity('student_accounts', 'student_account', studentId)
      .subscribe(studentData => {
        // Update the field_executive form control
        if (studentData?.['field_executive']?.uid) {
          this.enrollmentForm.patchValue({
            field_executive: studentData['field_executive'].uid
          });
        }

        // // Update the first field_instructor form control
        const instructorPercentages = this.enrollmentForm.get('field_instructor_percentages') as FormArray;
        if (instructorPercentages.length > 0) {
          if (studentData?.['field_teacher']?.uid) {
            instructorPercentages.at(0).patchValue({
              field_instructor: studentData['field_teacher'].uid
            });
          }
        } else {
          // If no instructor percentage exists, add a new one with the default values
          this.instructorPercentageIncrement();
          if (studentData?.['field_teacher']?.uid) {
            instructorPercentages.at(0).patchValue({
              field_instructor: studentData['field_teacher'].uid
            });
          }
        }
      });
  }

  override ngAfterViewInit(): void {
    this.agreementFormGroup = this._formBuilder.group({
      field_nothing: [''],
    });

    // // Signature stuff.
    this.signatureInit(this.signatureStudent, 'student');
    this.signatureInit(this.signatureStudio, 'studio');

    // Pull current tax rate.
    if (this.actionType == "create") {
      this._fieldsService.getTaxRateConfig().subscribe(data => {
        // this.enrollmentForm.get("field_tax_percentage").setValue(data?.[0]?.['value']);
        this.loadTaxRateFromConfig();
      });
    } else {
      // Respect the tax value if in edit mode.
    }

    this.getUniquePaymentID()
      .subscribe(data => {
        this.paymentID = data;
        // this.setFormMode("create");
      });

    // This is causing problems.
    // this.instructorPercentageIncrement();

    this.addGiftCertificate();
  }

  onSubmitPayment(f: NgForm, drop = false) {
    this.displayProgressSpinner(true);

    // Support for single or multiple enrollments.
    if (this.initActionType == "create" || this.initActionType == "refund") {
      this.errorMessage = "";

      // Copy the object to not modify binded data.
      let values = _.cloneDeep(f.value);
      this.__alterValues(values);

      if (this.initActionType == "create") {
        // Change "field_enrollment_name" to "enrollment_ids"
        values.enrollment_ids = values.field_enrollment_name;
      } else {
        // Refund
        values.enrollment_ids = [values.field_enrollment_name];
        values.field_gross_tuition = -(Math.abs(values.field_gross_tuition));
        values.field_stripe_charge_id = this.entityData?.field_stripe_charge_id;
      }

      // Support for refunds.
      if (this.initActionType == "refund") {
        this.paymentForm.value.field_gross_tuition = -(Math.abs(this.paymentForm.value.field_gross_tuition));
        this.actionType == "refund"
        // this.onSubmit(this.paymentForm);
      }

      let body = {
        ...values,
      }

      this._drupalRESTService.httpPOST('/api_rest/v1/postPayment', body).subscribe(data => {

        this.displayProgressSpinner(false);
        this.setStepperIndex(3);
      },
        error => this.handleError(error)
      )
    } else {
      // Otherwise submit as normal to eckEntityWrapper.
      this.onSubmit(this.paymentForm);
    }
  }

  onPaymentSubmitted(paymentResponse: any) {
    if (paymentResponse.success) {
      console.log('Payment submitted successfully:', paymentResponse);

      // Update enrollment data
      this.updateEnrollmentAfterPayment(paymentResponse);

      // Display success message
      this.showSuccessMessage('Payment processed successfully');

      // Move to the next step in the stepper
      this.setStepperIndex(3);
    } else {
      console.error('Payment submission failed:', paymentResponse.error);
      this.handlePaymentError(paymentResponse.error);
    }
  }

  private updateEnrollmentAfterPayment(paymentResponse: any) {
    // Update the enrollment entity with the new payment information
    const updatedEnrollmentData = {
      ...this.enrollmentEntityData,
      field_total_paid: (parseFloat(this.enrollmentEntityData.field_total_paid) + parseFloat(paymentResponse.amount)).toFixed(2),
      field_balance_due: (parseFloat(this.enrollmentEntityData.field_balance_due) - parseFloat(paymentResponse.amount)).toFixed(2),
      field_payment_status: this.determinePaymentStatus(paymentResponse.amount)
    };

    this._entityRESTService.patchEntity(this.eckType, this.bundle, this.entityId, updatedEnrollmentData)
      .subscribe(
        updatedEnrollment => {
          console.log('Enrollment updated after payment:', updatedEnrollment);
          this.enrollmentEntityData = updatedEnrollment;
        },
        error => console.error('Error updating enrollment after payment:', error)
      );
  }

  private determinePaymentStatus(paidAmount: number): string {
    const totalDue = parseFloat(this.enrollmentEntityData.field_total_price_with_est_tax);
    const totalPaid = parseFloat(this.enrollmentEntityData.field_total_paid) + paidAmount;

    if (totalPaid >= totalDue) {
      return 'Paid in Full';
    } else if (totalPaid > 0) {
      return 'Partially Paid';
    } else {
      return 'Unpaid';
    }
  }

  private showSuccessMessage(message: string) {
    // Implement your preferred method of showing success messages
    // For example, using Angular Material's MatSnackBar:
    this._snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  private handlePaymentError(error: any) {
    // Implement your error handling logic
    // For example, showing an error message to the user
    this._snackBar.open('Payment failed: ' + error.message, 'Close', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['error-snackbar']
    });

    // Optionally, you might want to reset the payment form or take other actions
  }

  private isLastStep(): boolean {
    // Implement logic to determine if this is the last step in your enrollment process
    return this.enrollmentStepper.selectedIndex === this.enrollmentStepper.steps.length - 1;
  }

  private handleEnrollmentFinalizationError(error: any) {
    // Handle errors that occur during enrollment finalization
    this._snackBar.open('Error finalizing enrollment: ' + error.message, 'Close', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['error-snackbar']
    });
  }

  calcScheduledPayments() {
    this.enrollmentForm.get('interval').value;
    this.enrollmentForm.get('field_payments_structure').value;

    let interval = this.enrollmentForm.get('interval').value;
    let startIntervalDate = moment();

    let totalPayments = this.calcNewTotal();
    let totalPaymentsDivideByInterval = totalPayments / interval;

    // The start date.
    let newGeneratedDate = moment(this.enrollmentForm.get('field_recurrent_month_day').value, 'DD');

    if (!this.enrollmentForm.get('field_recurrent_month_day').value) {
      alert('Please select a start date.');
      return;
    }

    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).clear();

    let roundedPaymentAmount = Math.floor(totalPaymentsDivideByInterval * 100) / 100;
    let firstPaymentAmount = (totalPayments - roundedPaymentAmount * (interval - 1)).toFixed(2);

    let i;
    let o = 0;

    if (this.enrollmentForm.get('field_payments_structure_type').value == 'monthly' || this.enrollmentForm.get('field_payments_structure_type').value == 'weekly') {
      for (i = 0; i < interval; i++) {
        let paymentAmount = (i === 0) ? firstPaymentAmount : roundedPaymentAmount.toFixed(2);
        let taxAmount = this.calculateEstimatedTax(parseFloat(paymentAmount));

        if (this.enrollmentForm.get('field_payments_structure_type').value == 'monthly') {
          newGeneratedDate = moment(this.enrollmentForm.get('field_recurrent_month_day').value, 'DD').add(o, 'months');

          let controlsConfig = {
            'id': [null],
            'field_amount_paid': [null],
            'field_enrollment': [null],
            'field_paid_in_full': [false],
            'field_down_payment': [false, Validators.required],
            'field_payment_amount': [paymentAmount, Validators.required],
            'field_tax_amount': [taxAmount],
            'field_payment_date': [newGeneratedDate.format('YYYY-MM-DD'), Validators.required],
            'field_number_lesson_remaining': [null],
            'field_studio_reference': [this._authService.studios?.[0]?.id],
            'bundle': ['scheduled_payments', Validators.required],
            'type': ['scheduled_payments', Validators.required],
          };

          this.scheduledPaymentIncrement(controlsConfig);

          // Check for steps payment_builder_date_step
          this.enrollmentForm.get('payment_builder_date_step').value.forEach(element => {
            i = i + 1;
            // Don't allow more than the interval.
            if (i < interval) {
              let newGeneratedDateInterval = moment(newGeneratedDate).set("date", element.field_recurrent_month_day);

              let controlsConfig = {
                'id': [null],
                'field_amount_paid': [null],
                'field_enrollment': [null],
                'field_paid_in_full': [false],
                'field_down_payment': [false, Validators.required],
                'field_payment_amount': [paymentAmount, Validators.required],
                'field_tax_amount': [taxAmount],
                'field_payment_date': [newGeneratedDateInterval.format('YYYY-MM-DD'), Validators.required],
                'field_number_lesson_remaining': [null],
                'field_studio_reference': [this._authService.studios?.[0]?.id],
                'bundle': ['scheduled_payments', Validators.required],
                'type': ['scheduled_payments', Validators.required],
              };

              this.scheduledPaymentIncrement(controlsConfig);
            }
          });

          o++;
        }

        if (this.enrollmentForm.get('field_payments_structure_type').value == 'weekly') {
          let weekdays = [];
          if (this.enrollmentForm.get('field_recurrent_weekday_su').value) { weekdays.push(0); }
          if (this.enrollmentForm.get('field_recurrent_weekday_mo').value) { weekdays.push(1); }
          if (this.enrollmentForm.get('field_recurrent_weekday_tu').value) { weekdays.push(2); }
          if (this.enrollmentForm.get('field_recurrent_weekday_we').value) { weekdays.push(3); }
          if (this.enrollmentForm.get('field_recurrent_weekday_th').value) { weekdays.push(4); }
          if (this.enrollmentForm.get('field_recurrent_weekday_fr').value) { weekdays.push(5); }
          if (this.enrollmentForm.get('field_recurrent_weekday_sa').value) { weekdays.push(6); }

          if (weekdays.length == 0) { return; }

          while (!weekdays.includes(newGeneratedDate.day())) {
            newGeneratedDate.add(1, 'day');
          }

          let controlsConfig = {
            'id': [null],
            'field_amount_paid': [null],
            'field_enrollment': [null],
            'field_paid_in_full': [false],
            'field_down_payment': [false, Validators.required],
            'field_payment_amount': [paymentAmount, Validators.required],
            'field_tax_amount': [taxAmount],
            'field_payment_date': [newGeneratedDate.format('YYYY-MM-DD'), Validators.required],
            'field_number_lesson_remaining': [null],
            'field_studio_reference': [this._authService.studios?.[0]?.id],
            'bundle': ['scheduled_payments', Validators.required],
            'type': ['scheduled_payments', Validators.required],
          };

          this.scheduledPaymentIncrement(controlsConfig);

          let added = false;
          while (!added) {
            newGeneratedDate.add(1, 'day');
            if (weekdays.includes(newGeneratedDate.day())) {
              added = true;
            }
          }
        }
      }
    }

    if (this.enrollmentForm.get('field_payments_structure_type').value == 'by_the_lesson') {
      let interval = this.enrollmentForm.get('interval').value;  // The number of lessons per payment
      let totalLessons = this.enrollmentForm.get('field_enrollment_lesson_count').value;  // Total lessons in the course
      let lessonPrice = this.enrollmentForm.get('field_enrollment_lesson_price').value;  // Price per lesson

      // Dynamically calculate total price based on lessons and price per lesson
      let totalPrice = totalLessons * lessonPrice;  // Total cost of all lessons
      let newGeneratedDate = moment(this.enrollmentForm.get('field_recurrent_month_day').value);

      // Get the price with the discount applied.
      totalPrice = this.calcNewTotal();

      // Calculate the price per lesson based on the total price and total lessons
      let pricePerLesson = totalPrice / totalLessons;

      let lessonsRemaining = totalLessons;
      let paymentCounter = 0;

      while (lessonsRemaining > 0) {
        let lessonsCovered;

        // Determine how many lessons this installment will cover (interval or remainder)
        if (lessonsRemaining >= interval) {
          lessonsCovered = interval;
        } else {
          lessonsCovered = lessonsRemaining;  // Final payment covers the remaining lessons
        }

        // Calculate the payment amount based on the number of lessons covered
        let paymentAmount = pricePerLesson * lessonsCovered;

        // Tax calculation (if applicable)
        let taxAmount = this.calculateEstimatedTax(paymentAmount);

        // Configure the form controls for this payment
        let controlsConfig = {
          'id': [null],
          'field_amount_paid': [null],
          'field_enrollment': [null],
          'field_paid_in_full': [false],
          'field_down_payment': [false, Validators.required],
          'field_payment_amount': [paymentAmount.toFixed(2), Validators.required],
          'field_tax_amount': [taxAmount],
          'field_payment_date': [(paymentCounter == 0) ? newGeneratedDate.format('YYYY-MM-DD') : null],
          'field_number_lesson_remaining': [lessonsRemaining],
          'field_studio_reference': [this._authService.studios?.[0]?.id],
          'bundle': ['scheduled_payments', Validators.required],
          'type': ['scheduled_payments', Validators.required],
        };

        // Add the payment installment to the form array
        this.scheduledPaymentIncrement(controlsConfig);

        // Reduce the remaining lesson count
        lessonsRemaining -= lessonsCovered;
        paymentCounter++;
      }
    }
  }


  scheduledPaymentIncrement(controlsConfig?) {
    let control = this._formBuilder.group(controlsConfig ?? {
      'id': [null],
      'field_amount_paid': [null],
      'field_enrollment': [null],
      'field_paid_in_full': [false],
      'field_down_payment': [false, Validators.required],
      'field_payment_amount': [null, Validators.required],
      'field_tax_amount': [null],
      'field_payment_date': [null, Validators.required],
      'field_number_lesson_remaining': [null],
      'field_studio_reference': [this._authService.studios?.[0]?.id],
      'bundle': ['scheduled_payments', Validators.required],
      'type': ['scheduled_payments', Validators.required],
    });

    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).push(control)
  }

  scheduledPaymentRemove(index: number) {
    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).removeAt(index)
  }

  public instructorPercentageIncrement() {
    // Default the first added percentage to 100.
    let instructor_percentage = (this.enrollmentForm.get('field_instructor_percentages')?.['controls'] == 0) ? 100 : 0;

    let control = this._formBuilder.group({
      'id': [null],
      'field_amount': [null, Validators.required],
      // 'field_enrollment': [null, Validators.required],
      'field_instructor': [null, Validators.required],
      'field_percentage': [instructor_percentage, Validators.required],
      'field_studio_reference': [this._authService.studios?.[0]?.id],
      'bundle': ['instructor_percentages', Validators.required],
      'type': ['instructor_percentages', Validators.required],
    });

    (<FormArray>this.enrollmentForm.get('field_instructor_percentages')).push(control)

    this.fromPercentCalcTotal();
  }

  validationErrors = [];

  instructorPercentageRemove(index: number) {
    (<FormArray>this.enrollmentForm.get('field_instructor_percentages')).removeAt(index)
  }

  resetInstructorFormGroup() {
    this.enrollmentMessage = "";
    this.autoCompleteStudentContactOptions = null;
    this.enrollmentsForUser = null;
    this.enrollmentForm.controls['__field_students_inline_form'].reset();
  }

  onPackageLookup($event: { option: { value: any; }; }) {

    this._entityRESTService.getEntity('packages', 'package', this.regexParamID($event.option.value))
      .subscribe(response => {
        this.enrollmentForm.patchValue({
          field_enrollment_total_price: response['field_total_price'],
          field_enrollment_lesson_price: response['field_lesson_price'],
          field_enrollment_lesson_count: response['field_lesson_count'],
          field_discount: response['field_discount'] || 0,
          field_discount_type: response['field_discount_type'] || 'flat',
        });

        this.calcNewTotal();
      })

  }

  paid_in_full() {
    this.is_pay_in_full = true;
    this.is_use_installments = false;

    // Clear the current form.
    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).clear();

    // Calculate data needed.
    console.log('field_enrollment_total_price', this.enrollmentForm.get('field_enrollment_total_price').value, this.calculateEstimatedTax(this.enrollmentForm.get('field_enrollment_total_price').value));
    // Add the newly calculated data.
    let control = this._formBuilder.group({
      'id': [null],
      'field_amount_paid': [null],
      'field_enrollment': [null],
      'field_paid_in_full': [true],
      'field_down_payment': [true, Validators.required],
      'field_payment_amount': [this.enrollmentForm.get('field_enrollment_total_price').value, Validators.required],
      'field_tax_amount': [this.calculateEstimatedTax(this.enrollmentForm.get('field_enrollment_total_price').value)],
      'field_payment_date': [this.enrollmentForm.get('field_sale_date').value ?? moment().format('YYYY-MM-DD'), Validators.required],
      'field_scheduled_payments': [null],
      'field_number_lesson_remaining': [null],
      'field_studio_reference': [this._authService.studios?.[0]?.id],
      'bundle': ['scheduled_payments', Validators.required],
      'type': ['scheduled_payments', Validators.required],
    });

    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).push(control);
  }

  use_installments() {
    this.is_use_installments = true;
    this.is_pay_in_full = false;

    // Clear the current form.
    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).clear();

    // Add the newly calculated data.
    let control = this._formBuilder.group({
      'id': [null],
      'field_amount_paid': [null],
      'field_enrollment': [null],
      'field_paid_in_full': [false],
      'field_down_payment': [false, Validators.required],
      'field_payment_amount': [null, Validators.required],
      'field_tax_amount': [null],
      'field_payment_date': [null, Validators.required],
      'field_scheduled_payments': [null],
      'field_number_lesson_remaining': [null],
      'field_studio_reference': [this._authService.studios?.[0]?.id],
      'bundle': ['scheduled_payments', Validators.required],
      'type': ['scheduled_payments', Validators.required],
    });

    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).push(control);
  }

  paymentPlanGeneratorIncreaseStep() {
    // Add the newly calculated data.
    let control = this._formBuilder.group({
      field_recurrent_month_day: 'first',
    });

    (<FormArray>this.enrollmentForm.get('payment_builder_date_step')).push(control);
  }

  paymentPlanGeneratorRemoveStep(index) {
    (<FormArray>this.enrollmentForm.get('payment_builder_date_step')).removeAt(index)
  }

  onSubmitEnrollment(f: NgForm, mode) {
    this.errorMessage = "";

    // Calculate instructor percentenages.
    this.fromPercentCalcTotal();

    // Calculate Pay In Full or Installments.
    if (this.is_pay_in_full) {
      this.paid_in_full();
    } else if (this.is_use_installments) {
      // this.use_installments();
    }

    this.displayProgressSpinner(true);

    let values = f.value;

    if (this.actionType == "create") {
      this.__alterValues(values);

      this._entityRESTService.createEntity(this.eckType, this.bundle, values)
        .subscribe(data => {
          let body = data;
          // Defensive check for 'id' property and its array value
          if (body && Array.isArray(body['id']) && body['id'].length > 0 && body['id'][0].value) {
            this._entityRESTService.getEntity('packages', 'enrollment', body['id'][0].value)
              .subscribe(data => {
                this.entityData = data;
                this.enrollmentEntityData = data;
              });
          } else {
            console.error("Unexpected structure for 'id' in data:", data);
          }

          // Defensive check for 'field_student' property and its array value
          if (body && Array.isArray(body['field_student']) && body['field_student'].length > 0 && body['field_student'][0].target_id) {
            this._entityRESTService.getEntity('student_accounts', 'student_account', body['field_student'][0].target_id)
              .subscribe(data => {
                this.studentAccountEntityData = data;
              });
          } else {
            console.error("Unexpected structure for 'field_student' in data:", data);
          }

          this.displayProgressSpinner(false);
          this.setStepperIndex(1);
        },
          error => this.handleError(error)
        )
    }

    // Edit enrollments.
    if (mode == "edit") {
      // Set field_enrollment_lesson_price to a string.
      this.__alterValues(f.value);

      this._entityRESTService.patchEntity(this.eckType, this.bundle, this.entityId, values)
        .subscribe(data => {
          this.displayProgressSpinner(false);
          this._dialog.closeAll();
        },
          error => this.handleError(error)
        )
    }
  }

  calcDiscount() {
    let formValues = this.enrollmentForm.value;

    // Initialize discountTotal as a number.
    this.discountTotal = 0;

    // Calculate a percentage discount.
    if (formValues.field_discount_type == 'percentage') {
      this.discountTotal = (
        (parseFloat(formValues.field_enrollment_lesson_price) * parseFloat(formValues.field_enrollment_lesson_count)) *
        (parseFloat(formValues.field_discount) / 100)
      );
    }

    // Calculate a flat discount.
    else if (formValues.field_discount_type == 'flat') {
      this.discountTotal = parseFloat(formValues.field_discount);
    }

    // Ensuring discountTotal is a number before calling toFixed
    let discountTotalRounded = parseFloat(this.discountTotal).toFixed(2);

    // Since discountTotalRounded is a string, it can be directly assigned
    this.displayDiscountTotalString = discountTotalRounded;

    // Returning the number version of the rounded discount for any further calculations
    return parseFloat(discountTotalRounded);
  }

  calcLessonTotal() {
    let formValues = this.enrollmentForm.value;

    // If non-unit (66) is 0, return 0.
    if (formValues.field_category == '66' && formValues.field_enrollment_lesson_count == 0) {
      return (formValues.field_enrollment_lesson_price);
    }

    // If the category is misc (5) or sundry (67), return the lesson price.
    if (formValues.field_category == '5' || formValues.field_category == '67') {
      // Update whatever lesson count is to 0
      this.enrollmentForm.patchValue({
        field_enrollment_lesson_count: 0,
      });

      return (formValues.field_enrollment_lesson_price);
    } else {
      return (formValues.field_enrollment_lesson_price * formValues.field_enrollment_lesson_count);
    }
  }

  calcTotalWithDiscount() {
    let formValues = this.enrollmentForm.value;
    return this.calcLessonTotal() - this.calcDiscount() - this.giftCertificateAmount;
  }

  calcTotalWithTaxes(totalBeforeTaxes: number) {
    let total = 0
    this.taxRate = this.enrollmentForm.get("field_tax_percentage").value ?? 0;
    total = totalBeforeTaxes + (totalBeforeTaxes * (this.taxRate / 100))
    return total;
  }

  /**
   *
   * @returns The total before taxes.
   */
  calcNewTotal() {
    console.log('calcNewTotal');
    let formValues = this.enrollmentForm.value;
    let total;
    let totalBeforeTaxes;
    let lessonPriceTotal;

    // Tax, Discount, and Gift Certificate calculations.
    lessonPriceTotal = this.calcLessonTotal();
    totalBeforeTaxes = this.calcTotalWithDiscount() - this.giftCertificateAmount;
    total = this.calcTotalWithTaxes(totalBeforeTaxes);

    // Round totals.
    lessonPriceTotal = Number.parseFloat(lessonPriceTotal).toFixed(2);
    totalBeforeTaxes = Number.parseFloat(totalBeforeTaxes).toFixed(2);
    total = Number.parseFloat(total).toFixed(2);

    // Check for non real numbers.
    if (isNaN(totalBeforeTaxes) || isNaN(total)) {
    } else {
      console.log('total', total);
      this.enrollmentForm.patchValue({
        field_enrollment_total_price: totalBeforeTaxes ?? 0,
        field_total_price_with_est_tax: total ?? 0,
        field_total_lessons_price: lessonPriceTotal ?? 0,
        field_taxes: (totalBeforeTaxes * (this.taxRate / 100)) ?? 0
      });
    }

    // Determine if enrollment is misc or sundry item.
    this.isMiscOrSundry = (formValues.field_category == 5 || formValues.field_category == 67) ?? false;

    // Balance limit: Check if total exceeds the balance limit of $20,000
    // If the enrollment is MISC, SUNDRY, or non-unit (66), then do not check the balance limit.
    if (this.studentBalanceData && parseFloat(total) > parseFloat(this.studentBalanceData?.remaining_amount) && !this.isMiscOrSundry && formValues.field_category != 66) {
      this.studentBalanceWarning = 'Total exceeds the balance limit of $20,000.' + ' Remaining: $' + this.studentBalanceData?.remaining_amount;
      return;
    } else {
      this.studentBalanceWarning = null;
    }

    // Base case where amount is over 20,000.
    // if (parseFloat(total) > 20000 && !this.isMiscOrSundry) {
    //   this.studentBalanceWarning = 'Total exceeds the balance limit of $20,000.';
    // }

    // Calculate instructor percentage when total update.
    this.fromPercentCalcTotal();

    return totalBeforeTaxes;
  }

  fromPercentCalcTotal() {
    let formValues = this?.enrollmentForm?.value;
    let total = this.calcTotalWithDiscount();
    let amountPercentage: number;

    let field_instructor_percentages_array = [];
    if (formValues?.field_instructor_percentages) {
      formValues?.field_instructor_percentages?.forEach((values => {
        let amountPercentage = (values.field_percentage / 100);
        values.field_percentage = amountPercentage * total;

        field_instructor_percentages_array.push({
          field_amount: Number.parseFloat(values.field_percentage).toFixed(2),
        })
      }));
    }

    this.enrollmentForm.patchValue({
      field_instructor_percentages: field_instructor_percentages_array
    });
  }

  updateCount($count) {
    this.eventCount = $count;
  }

  onEnrollmentSaleDate($event) {
    if ($event) {
      // Default the expiration date to 6 months from now.
      let date: string = $event?.target?.value ?? $event;
      if (this.enrollmentForm.get("field_expiration_date").value) {
      } else {
        this.enrollmentForm.get("field_expiration_date").setValue(moment(date).add(6, 'months'));
      }
    }
  }

  // Helper function to round a number to a fixed number of decimal places
  private roundNumber(num: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  }

  getOriginalPackageCost(): number {
    if (!this.entityData?.field_enrollment_total_price || !this.entityData?.field_discount_type || !this.entityData?.field_discount) {
      return 0; // Return 0 or another appropriate default value if data is not available
    }

    const packageCost = this.entityData.field_enrollment_total_price;
    const discount = this.entityData.field_discount;
    let result: number;

    if (this.entityData.field_discount_type === 'flat') {
      result = parseFloat(packageCost.toString()) + parseFloat(discount.toString());
    } else if (this.entityData.field_discount_type === 'percentage') {
      result = packageCost / (1 - (discount / 100));
    } else {
      result = packageCost; // Assuming no discount if type is neither 'flat' nor 'percentage'
    }

    // Round the result to a desired number of decimal places
    return this.roundNumber(result, 2); // Here, I'm rounding to 2 decimal places. Adjust as needed.
  }

  getAmountLabel(): string {
    const field_category = this.enrollmentForm?.value?.field_category;
    switch (field_category) {
      case '5': // MISC
        return 'Event Amount';
      case '67': // SUNDRY
        return 'Item Amount';
      default:
        return 'Lesson Amount';
    }
  }

  sendEmailNewEnrollment(enrollmentID) {
    this._drupalRESTService.httpGET('/api_rest/v1/sendNewEnrollmentEmail?enrollmentID=' + enrollmentID)
      .subscribe(
        data => {
          console.log('API response:', data);
          if (data['status'] === 'error') {
            const errorMessage = data['message'] || 'An error occurred while sending the email.';
            // Snackbar message that is centered.
            this._snackBar.open(errorMessage, 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
            });
          } else {
            // Snackbar message that is centered.
            this._snackBar.open('Email sent successfully.', 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
            });
          }
        },
        error => {
          console.error('Error sending email:', error);
          // Snackbar message that is centered.
          this._snackBar.open('An error occurred while sending the email.', 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
          });
        }
      );
  }

  getFieldTypeBasedOnDepartment(studentDepartment: number): string {
    if (studentDepartment == 75) {
      return "548";
    } else if (studentDepartment == 77) {
      return "600";
    } else {
      return null; // or return a default value if needed
    }
  }

  loadTaxRateFromConfig() {
    this._drupalRESTService.httpGET('/api_rest/v1/loadStudioConfig?config_field_name=field_tax_ref').subscribe(
      (response: any) => {
        if (!response || (Array.isArray(response) && response.length === 0)) {
          console.log('No tax configuration found');
          this.enrollmentForm.patchValue({ field_tax_percentage: 0 });
          this.calcNewTotal();
          return;
        }

        this.taxConfig = Array.isArray(response) ? response : [response];
        let totalTaxRate = 0;
        const currentDate = moment(); // Get the current date for comparison

        this.taxConfig.forEach(tax => {
          const startDate = tax.field_effective_tax_start_date?.[0]?.value ? moment(tax.field_effective_tax_start_date[0].value) : null;
          const endDate = tax.field_effective_tax_end_date?.[0]?.value ? moment(tax.field_effective_tax_end_date[0].value) : null;

          // Only include taxes that are currently effective
          if ((!startDate || currentDate.isSameOrAfter(startDate)) && (!endDate || currentDate.isSameOrBefore(endDate))) {
            const calculationMethod = Array.isArray(tax.field_calculation_method)
              ? tax.field_calculation_method[0]?.value
              : tax.field_calculation_method;

            const taxValue = Array.isArray(tax.field_tax_value)
              ? parseFloat(tax.field_tax_value[0]?.value)
              : parseFloat(tax.field_tax_value);

            if (calculationMethod === 'PERCENTAGE' && !isNaN(taxValue)) {
              totalTaxRate += taxValue;
            }
          }
        });

        if (totalTaxRate === 0) {
          console.log('No applicable percentage-based taxes found');
          this.enrollmentForm.patchValue({ field_tax_percentage: 0 });
          this.calcNewTotal();
          return;
        }

        const roundingRule = Array.isArray(this.taxConfig[0].field_rounding_rule)
          ? this.taxConfig[0].field_rounding_rule[0]?.value
          : this.taxConfig[0].field_rounding_rule;

        const roundedTaxRate = this.applyRoundingRule(totalTaxRate, roundingRule || 'ROUND_NEAREST');
        this.enrollmentForm.patchValue({ field_tax_percentage: roundedTaxRate });
        this.calcNewTotal();
      },
      error => {
        console.error('Error loading tax rate:', error);
        this.enrollmentForm.patchValue({ field_tax_percentage: 0 });
        this.calcNewTotal();
      }
    );
  }

  applyRoundingRule(value: number, rule: string): number {
    switch (rule) {
      case 'ROUND_UP':
        return Math.ceil(value * 100) / 100;
      case 'ROUND_DOWN':
        return Math.floor(value * 100) / 100;
      case 'ROUND_NEAREST':
        return Math.round(value * 100) / 100;
      case 'NO_ROUNDING':
      default:
        return parseFloat(value.toFixed(2));
    }
  }

  calculateTotalTuition() {
    const originalCost = this.getOriginalPackageCost();
    let discountedCost = originalCost;

    if (this.entityData?.field_discount_type === 'percentage') {
      const discountPercentage = this.entityData?.field_discount || 0;
      discountedCost = originalCost * (1 - discountPercentage / 100);
    } else if (this.entityData?.field_discount_type === 'flat') {
      const discountAmount = this.entityData?.field_discount || 0;
      discountedCost = originalCost - discountAmount;
    }

    const taxRate = this.entityData?.field_tax_percentage || 0;
    const taxAmount = discountedCost * (taxRate / 100);

    return discountedCost + taxAmount;
  }

  checkStudentBalanceLimit(studentAccountId: string) {
    studentAccountId = this.regexStudentName(studentAccountId);

    const endpoint = '/api_rest/v1/checkStudentBalanceLimit';

    if (this.actionType !== 'create') {
      this.studentBalanceWarning = null;
      return; // Only check balance limit in create mode
    }

    this._drupalRESTService.httpGET(endpoint + '?studentAccountId=' + studentAccountId).subscribe(
      (response: any) => {
        if (!response || (Array.isArray(response) && response.length === 0)) {
          console.log('No response from checkStudentBalanceLimit');
          this.studentBalanceWarning = null;
          return;
        }

        this.studentBalanceData = response;

        if (!response.is_under_threshold) {
          this.studentBalanceWarning = `Balance limit exceeded. Total: $${response.total_balance.toFixed(2)}, Remaining: $${response.remaining_amount.toFixed(2)}`;
        } else {
          this.studentBalanceWarning = null;
        }

        this.calcNewTotal();
      },
      (error) => {
        console.error('Error checking student balance limit:', error);
        this.studentBalanceWarning = null;
      }
    );
  }

}

