<div class="tutorial-card">
  <!-- <div class="iframe-container"> -->
    <iframe
      [src]="safeUrl"
      class="tutorial-iframe"
      sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
      security="restricted"
      title="{{ tutorialData.title }}"
      height="1000px"
			width="1000px"
      referrerpolicy="strict-origin-when-cross-origin"
      frameborder="0"
      webkitallowfullscreen="webkitallowfullscreen"
      mozallowfullscreen="mozallowfullscreen"
      allowfullscreen="allowfullscreen">
    </iframe>
  <!-- </div> -->
</div>
