import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { Router, ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import moment from 'moment';
import { DialogService } from 'src/app/services/dialog.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { UtilityService } from 'src/app/services/utility.service';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { environment } from 'src/environments/environment';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { RouteEventsService } from 'src/app/route-events.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ConfigPagesService } from 'src/app/services/config-pages.service';
import { PackagesService } from 'src/app/services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';

@Component({
  selector: 'app-base-form',
  template: '',
})
export class BaseComponent {
  constructor(
    public _dialogService: DialogService,
    public _entityRESTService: EntityRESTService,
    public _utilityService: UtilityService,
    public _fieldsService: FieldsService,
    public _drupalRESTService: DrupalRESTService,
    public _taxonomyService: TaxonomyService,
    public _router: Router,
    public _activatedRoute: ActivatedRoute,
    public _authRESTService: AuthRESTService,
    public _authService: AuthService,
    public _menuRESTService: MenuRESTService,
    public _formBuilder: FormBuilder,
    public _routeEventsService: RouteEventsService,
    public _permissionsService: PermissionsService,
    public _renderer2: Renderer2,
    public _configPages: ConfigPagesService,
    public _packagesService: PackagesService,
    public _snackBar: MatSnackBar,
    public _userRESTService: UserService,
    public _el: ElementRef,
    public _changeDetectorRef: ChangeDetectorRef,
    public _sanitizer: DomSanitizer,
  ) {
    this._drupalRESTService._csrfToken();
  }

  @Output() dataChangedEvent = new EventEmitter<string>();
  @ViewChild(MatExpansionPanel) pannel?: MatExpansionPanel;
  @ViewChild(NgForm) f: NgForm;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('picker_field_date_time') pickerFieldDateTime: NgxMatDatetimePicker<any>;

  previousUrl: string[];
  currentUrl: string[];
  data: any;
  initActionType: "create" | "view" | "edit" | "delete" | "refund" | "editEnrollment" | "signEnrollment";
  actionType: "create" | "view" | "edit" | "delete" | "refund" | "editEnrollment" | "signEnrollment";
  entityData: any;
  _entityData: any;
  _frmValues: any;
  enrollmentID?: any;
  studentAccountEntityData: any;
  enrollmentEntityData;
  showProgressSpinner: boolean = false;
  errorMessage: string;
  successMessage: string;
  apiMessage: any;
  autoCompleteStudentOptions: any = [];
  autoCompleteStudentContactOptions: any = [];
  autoCompleteBaseLessonOptions: Object;
  todaysDate: Date = new Date();
  enrollmentsForUser;
  selectedIndex = 0;
  field_date_and_time: string = "";
  field_booked_on: string;// = moment().format('YYYY-MM-DD');
  field_showed: string;// = moment().format('YYYY-MM-DD');
  field_expiration: string = '';
  editMode = false;
  editIndex: number;
  studentList = [];
  field_students = [];
  field_student: any = [];
  autoCompletePackageOptions: any = [];
  autoCompleteEmailOptions: any = [];
  autoCompletePhoneOptions: any = [];
  recurringEvent: boolean = false;
  field_scheduled_payments: any;
  isDisabled: boolean;
  calendarDateInquiry = moment().format('YYYY-MM-DD[T]12:00:00');
  cc_data: any = { cc_number: "", cc_expiration_month: "", cc_expiration_year: "", cc_expiration: "", cc_card_type: "" };
  isEventLocked: boolean;
  filters;
  isTablet = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  showTicket: boolean;

  paymentForm: FormGroup;
  lessonBlockForm: FormGroup;
  studentAccountForm: FormGroup;
  studentAccountDetailsForm: FormGroup;
  enrollmentForm: FormGroup;
  enrollmentEditForm: FormGroup;
  agreementFormGroup: FormGroup;
  miscServiceDeductionsFormGroup: FormGroup;

  enrollmentHelper: string = "";

  onSubmit(form: NgForm) {
    console.log(form.value);
  }

  // Loop through form values, match names, patch form values.
  setFormValues(formRef = null) {
    // Temporary alter entityData for processing.
    this._entityData = _.cloneDeep(this.entityData);
    this._entityData = Object.entries(this._entityData);

    let frmValues: {}, txtEntityProp: any, newValue: any, txtFormProperty: string | any[], toArray = [];

    let ngForm, ngFormValues;

    if (formRef) {
      ngForm = formRef.form;
      ngFormValues = formRef.value;
    } else {
      // Try to detect forms that might be reactive.
      if (this.f?.form?.value) {
        ngForm = this.f.form;
        ngFormValues = this.f.form.value;
      }

      if (this.paymentForm?.value) {
        ngForm = this.paymentForm
        ngFormValues = this.paymentForm.value;
      }

      if (this.studentAccountForm?.value) {
        ngForm = this.studentAccountForm
        ngFormValues = this.studentAccountForm.value;
      }

      if (this.studentAccountDetailsForm?.value) {
        ngForm = this.studentAccountDetailsForm
        ngFormValues = this.studentAccountDetailsForm.value;
      }

      if (this.enrollmentForm?.value) {
        ngForm = this.enrollmentForm
        ngFormValues = this.enrollmentForm.value;
        this.enrollmentHelper = this.enrollmentForm.value.field_payments_structure_type;
      }

      if (this.enrollmentEditForm?.value) {
        ngForm = this.enrollmentEditForm
        ngFormValues = this.enrollmentEditForm.value;
      }

      if (this.miscServiceDeductionsFormGroup?.value) {
        ngForm = this.miscServiceDeductionsFormGroup
        ngFormValues = this.miscServiceDeductionsFormGroup.value;
      }

      if (this.lessonBlockForm?.value) {
        ngForm = this.lessonBlockForm
        ngFormValues = this.lessonBlockForm.value;
      }

    }

    for (const entity in this._entityData) {
      // Process values.
      this._processFields(this._entityData[entity]);

      txtEntityProp = this._entityData[entity][0];
      for (const property in ngFormValues) {
        txtFormProperty = property;
        if (txtFormProperty.indexOf(txtEntityProp) > -1) {
          // Common (we use as the default) text values are usually located here.
          newValue = this._entityData[entity][1];

          if (property == "field_contacts") {
            let enumValue = Object.entries(this._entityData[entity]?.[1]);

            if (Array.isArray(this._entityData[entity]?.[1])) {
              for (let field_contact of enumValue) {
                let entityValue = enumValue[field_contact[0]]?.[1];
                if (entityValue) {
                  let control = this._formBuilder.group({
                    'target_id': [entityValue?.['id'], Validators.required],
                    'field_first_name': [entityValue?.['field_first_name'], Validators.required],
                    'field_last_name': [entityValue?.['field_last_name'], Validators.required],
                    'field_home_phone': [entityValue?.['field_last_name'], Validators.required],
                    'field_cell_phone': [entityValue?.['field_last_name'], Validators.required],
                    'field_email': [entityValue?.['field_last_name'], Validators.required],
                    'field_communication_opt_in': [entityValue?.['field_communication_opt_in'], Validators.required],
                    'field_studio_reference': [entityValue?.['field_studio_reference'], Validators.required],
                  });
                  (<FormArray>this.studentAccountDetailsForm.get('field_contacts'))?.push(control);
                }
              }
            } else {
              let entityValue = newValue;
              if (entityValue && entityValue?.['id'] && this.studentAccountDetailsForm?.value?.['field_contacts']) {
                let control = this._formBuilder.group({
                  'target_id': [entityValue?.['id'], Validators.required],
                  'field_first_name': [entityValue?.['field_first_name'], Validators.required],
                  'field_last_name': [entityValue?.['field_last_name'], Validators.required],
                  'field_home_phone': [entityValue?.['field_last_name'], Validators.required],
                  'field_cell_phone': [entityValue?.['field_last_name'], Validators.required],
                  'field_email': [entityValue?.['field_last_name'], Validators.required],
                  'field_communication_opt_in': [entityValue?.['field_communication_opt_in'], Validators.required],
                  'field_studio_reference': [entityValue?.['field_studio_reference'], Validators.required],
                });
                (<FormArray>this.studentAccountDetailsForm.get('field_contacts'))?.push(control);
              }
            }

          }

          // Support for nested entities, this trumps all post processing logic.
          if (typeof newValue == 'object' && newValue?.bundle && newValue?.type || typeof newValue == 'object' && newValue?.[0]?.bundle && newValue?.[0]?.type) {
            if (property == "field_instructor_percentages") {
              let enumValue = Object.entries(this._entityData[entity]?.[1]);
              for (let instructor_percentage of enumValue) {
                let entityValue = enumValue[instructor_percentage[0]]?.[1][0] ?? enumValue[instructor_percentage[0]]?.[1];
                if (entityValue?.id) {
                  let control = this._formBuilder.group({
                    'id': [entityValue['id'], Validators.required],
                    'field_amount': [entityValue['field_amount'], Validators.required],
                    'field_instructor': [entityValue?.['field_instructor'], Validators.required],
                    'field_percentage': [entityValue?.['field_percentage'], Validators.required],
                    'field_studio_reference_target_id': [entityValue['field_studio_reference'], Validators.required],
                    'field_studio_reference': [entityValue['field_studio_reference'], Validators.required],
                    'bundle': ['instructor_percentages', Validators.required],
                    'type': ['instructor_percentages', Validators.required],
                  });
                  (<FormArray>this.enrollmentForm.get('field_instructor_percentages')).push(control);
                }
              }
            }
            if (property == "field_scheduled_payments") {
              let enumValue = Object.entries(this._entityData[entity]?.[1]);
              for (let field_scheduled_payments of enumValue) {
                let entityValue = enumValue[field_scheduled_payments[0]]?.[1][0] ?? enumValue[field_scheduled_payments[0]]?.[1];
                if (entityValue?.id) {
                  let control = this._formBuilder.group({
                    'id': [entityValue['id'], Validators.required],
                    'field_amount_paid': [entityValue['field_amount_paid']],
                    'field_enrollment': [entityValue['field_enrollment']],
                    'field_paid_in_full': [entityValue['field_paid_in_full']],
                    'field_down_payment': [(entityValue['field_down_payment'] == "1" ? true : false), Validators.required],
                    'field_payment_amount': [entityValue['field_payment_amount'], Validators.required],
                    'field_tax_amount': [entityValue['field_tax_amount']],
                    'field_payment_date': [entityValue['field_payment_date'], Validators.required],
                    'field_number_lesson_remaining': [entityValue['field_number_lesson_remaining']],
                    'field_studio_reference_target_id': [entityValue['field_studio_reference'], Validators.required],
                    'field_studio_reference': [entityValue['field_studio_reference'], Validators.required],
                    'bundle': ['scheduled_payments', Validators.required],
                    'type': ['scheduled_payments', Validators.required],
                  });
                  (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).push(control);
                }
              }
            }
          } else {
            // Here's that post processing logic. It's got us far but I needed a better way to work with nested enities.
            // Check for an entity reference field value.
            if (typeof newValue == 'object') {
              // newValue = _entityData[entity][1]?.title;
              newValue = this._entityData[entity][1]?.id;

              // Pretty the 'field_student_name' field.
              if (txtFormProperty == "field_student_name") {
                // console.log(this.entityData[entity])
                if (this._entityData[entity][1]?.id && this._entityData[entity][1]?.title) {
                  this._entityData[entity][1].field_student_name = this._entityData[entity][1].title + " (" + this._entityData[entity][1]?.id + ")";
                  newValue = this._entityData[entity][1].title + " (" + this._entityData[entity][1]?.id + ")";
                }
              }

              // if field_instructor_alternate_list, the value should be an array.
              if (txtFormProperty == "field_instructor_alternate_list") {
                newValue = [];
              }

              // Special handling for field_students. TODO: clean this up to support all nested objects.
              if (txtFormProperty == "field_students" || txtFormProperty == "field_student") {
                // Support for single+multiple value entity objects. I need an array.
                if (Array.isArray(this._entityData[entity][1])) {
                  this._entityData[entity][1].forEach((element: any) => {
                    // Pretty the nested 'field_student_account' field.
                    if (element?.field_student_account && element?.title) {
                      element.field_student_account = element.title + " (" + element?.field_student_account + ")";
                    }

                    // Append correct "bundle" and "type"
                    if (element?.type && element.type == 'attendance') {
                      element.type = 'attendees';
                      element.bundle = 'attendance';
                    }

                    // Remove unused field_enrollment (causing an incorrect validation error)
                    if (element?.field_enrollment == '') {
                      delete element.field_enrollment;
                    }

                    toArray.push(element);
                  });
                  newValue = toArray;
                } else {

                  // Pretty the 'field_student_account' field.
                  if (this._entityData[entity][1]?.field_student_account && this._entityData[entity][1]?.title) {
                    this._entityData[entity][1].field_student_account = this._entityData[entity][1].title + " (" + this._entityData[entity][1]?.field_student_account + ")";
                    // this._entityData[entity][1].field_student_account = this._entityData[entity][1].title + " (" + this._entityData[entity][1]?.field_student_account.id + ")";
                  }

                  // Append correct "bundle" and "type"
                  if (this._entityData[entity][1]?.type && this._entityData[entity][1].type == 'attendance') {
                    this._entityData[entity][1].type = 'attendees';
                    this._entityData[entity][1].bundle = 'attendance';
                    this._entityData[entity][1].field_event_reference = this.entityData.id;
                  }

                  // Remove unused field_enrollment (causing an incorrect validation error)
                  if (this._entityData[entity][1]?.field_enrollment == '') {
                    delete this._entityData[entity][1].field_enrollment;
                  } else if (this._entityData[entity][1]?.field_enrollment) {
                    // this._entityData[entity][1].field_enrollment = this._entityData[entity][1].field_enrollment.id; // might remove this in the future.
                  }

                  toArray.push(this._entityData[entity][1]);
                  newValue = toArray;
                }
              }

              // Pretty the field_student field if it's an object and NOT an attendee.
              if (txtFormProperty == "field_student"
                && typeof this._entityData[entity][1] == 'object'
                && this._entityData[entity][1]?.type != 'attendees'
                && this._entityData[entity][1]?.[0]?.type != 'attendees'
              ) {
                newValue = this._entityData[entity][1].title + ' (' + this._entityData[entity][1].id + ')';
                // console.log('field_student1', newValue)
              }

              // Pretty the field_enrollment_package_name field if it's an object.
              if (txtFormProperty == "field_enrollment_package_name"
                && typeof this._entityData[entity][1] === 'object'
                && this._entityData[entity][1]?.type === 'package'
              ) {
                newValue = this._entityData[entity][1].title + ' (' + this._entityData[entity][1].id + ')';
              }

              // Pretty the field_students field if it's an object and NOT an attendee.
              if (txtFormProperty == "field_students"
                && typeof this._entityData[entity][1] == 'object'
                && this._entityData[entity][1]?.type != 'attendees'
                && this._entityData[entity][1]?.[0]?.['type'] != 'attendance'
                && this._entityData[entity][1]?.[0]?.type != 'attendees'
              ) {
                newValue = this._entityData[entity][1].title + ' (' + this._entityData[entity][1].id + ')';
              }

              if (txtFormProperty == "field_scheduled_payments") {
                newValue = this._entityData[entity][1].id;
              }

              // Support field_enrollment_name field.
              if (txtFormProperty == "field_enrollment_name" && typeof this._entityData[entity][1] == 'object') {
                newValue = this._entityData[entity][1].id;
              }

              // Special handling for payment enrollment stuff.
              if (txtFormProperty == 'field_enrollment_name') {
                newValue = {
                  target_id: this._entityData[entity][1].id
                }
              }
              if (txtFormProperty == 'field_scheduled_payment') {
                newValue = {
                  target_id: this._entityData[entity][1].id
                }
              }
            }

          }

          // Special handling for field_address
          if (txtFormProperty == "field_address") {
            newValue = {
              address_line1: this._entityData[entity][1]?.address_line1 || '',
              locality: this._entityData[entity][1]?.locality || '',
              administrative_area: this._entityData[entity][1]?.administrative_area || '',
              postal_code: this._entityData[entity][1]?.postal_code || '',
              country_code: this._entityData[entity][1]?.country_code || 'US',
            };
          }

          // Build the frmValues object, to patch the form later.
          frmValues = {
            ...frmValues,
            [txtFormProperty]: newValue,
          }
        }
      }
    }

    // Detect recurring events. TODO: this should be a flag from Drupal in the future.
    if ((frmValues?.['field_expiration_date'] != '') && frmValues?.['field_repetition_units'] && frmValues?.['field_repetition_frequency']) {
      console.log('setting recurring event to true')
      this.recurringEvent = true;
    }

    // Use _frmValues for some databinding.
    this._frmValues = frmValues;

    // Finally, load values into the form.
    if ((typeof ngForm) == 'object' && typeof ngForm.patchValue !== "undefined") {

      // Temporary fix for null values we handle in post processing.
      if (this?.studentAccountDetailsForm?.get('field_contacts')?.value) {
        delete frmValues?.['field_contacts'];
      }

      if (frmValues?.['field_instructor_percentages']) {
        // Convert the instructor percentages to an array
        const instructorPercentagesArray = Object.keys(frmValues['field_instructor_percentages'])
          .filter(key => !isNaN(parseInt(key))) // Filter out numeric keys only
          .map(key => frmValues['field_instructor_percentages'][key]); // Map to array
        frmValues['field_instructor_percentages'] = instructorPercentagesArray;
      }

      if (frmValues?.['field_scheduled_payments']) {
        // Convert the scheduled payments to an array
        const scheduledPaymentsArray = Object.keys(frmValues['field_scheduled_payments'])
          .filter(key => !isNaN(parseInt(key))) // Filter out numeric keys only
          .map(key => frmValues['field_scheduled_payments'][key]); // Map to array
        frmValues['field_scheduled_payments'] = scheduledPaymentsArray;
      }
      ngForm.patchValue(frmValues);

    } else { console.log('Error, form values not set.') }
  }

  /**
   * Handle misc. data processing here.
   */
  _processFields(field: any[]) {
    // Process dates to a format Drupal will accept.
    if (field[0] == 'field_date_and_time') {
      field[1] = moment(field[1]).format('YYYY-MM-DD[T]HH:mm:ss');
    }

    if (field[0] == 'field_booked_on' || field[0] == 'field_showed') {
      field[1] = moment(field[1]).format('YYYY-MM-DD');
    }

    // Respect field_visibility boolean value.
    if (field[0] == 'field_visibility') {
      field[1] = (field[1] == "1") ? true : false;
    }

    if (field[0] == 'field_use_alternating_instructor') {
      field[1] = (field[1] == "1") ? true : false;
    }

    // Respect boolean values.
    if (field[0] == 'field_communication_opt_in') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_tax_status') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_retired') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_is_gift_certificate') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_recurrent_weekday_mo') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_recurrent_weekday_tu') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_recurrent_weekday_we') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_recurrent_weekday_th') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_recurrent_weekday_fr') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_recurrent_weekday_sa') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_recurrent_weekday_su') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_inquiry_studen') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_is_confirmed') {
      field[1] = (field[1] == "1") ? true : false;
    }
    if (field[0] == 'field_is_system_package') {
      field[1] = (field[1] == "1") ? true : false;
    }
  }

  updateStatusMessageHTTP(message: string) {
    // TODO: display some error message in form area
  }

  emitDataChangedEvent() {
    this.dataChangedEvent.emit("refresh");
  }

  getEntity(eckType: string, bundle: string, EntityID: number, formRef?) {
    this.displayProgressSpinner(true);

    this._entityRESTService.getEntity(eckType, bundle, EntityID)
      .subscribe((data: any) => {
        // If initActionType is refund, parse field_stripe_charge_object as json. TODO: this should be differently.
        if (this.initActionType == 'refund') {
          try {
            data.field_stripe_charge_object = JSON.parse(data?.['field_stripe_charge_object']);
          } catch (e) {
            // Do nothing.
          }
        }

        // console.log('data from entityRESTservice', data);
        this.entityData = data;

        // Progress spinner.
        this.displayProgressSpinner(false);

        // Update form values
        this.setFormValues(formRef);

        // Events get locked when already charged.
        if (eckType == 'events' && bundle == 'lesson') {
          this.isEventLocked = (data?.['field_status'] == 59) || (data?.['field_status'] == 60);
        }

      })
  }

  onEnrollmentSelectThenLoadScheduledPayments($enrollmentID: any) {
    if (!$enrollmentID) { return };
    let enrollmentID = $enrollmentID;
    let endpoint = "/api_entity/eck/packages/enrollment/" + enrollmentID;
    let params = [
      {
        parameter: 'enrollmentID',
        value: enrollmentID
      }, {
        parameter: '_format',
        value: 'json'
      },
    ];

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe(data => {
        this.field_scheduled_payments = data?.['field_scheduled_payments'];

        // if (this.is_array(data?.['field_scheduled_payments'])) {
        //   this.field_scheduled_payments = data?.['field_scheduled_payments'];
        // } else {
        //   this.field_scheduled_payments = this.convertToArray([data?.['field_scheduled_payments']])
        // }
      });
  }

  onDeleteSubmit(form: NgForm) {

  }

  regexParamID(field) {
    if (!field) { return; }
    if (!isNaN(+field)) { return; }

    let regExp = /\(([^)]+)\)/g;
    let matches = field.match(regExp);

    return matches[matches.length - 1].replace(/[\(\)]/g, '');
  }

  regexStudentName(field_student_name: string) {
    if (!field_student_name) { return; }
    if (!isNaN(+field_student_name)) { return; }

    // Adjusted regex to match the content of the last set of parentheses
    let regExp = /\(([^)]+)\)(?!.*\()/;
    let matches = regExp.exec(field_student_name);

    if (!matches) { return; }

    return matches[1];
  }

  regexPackageName(field_enrollment_package_name: string) {
    if (!field_enrollment_package_name) { return; }
    if (!isNaN(+field_enrollment_package_name)) { return; }

    let regExp = /\(([^)]+)\)/;
    let matches = regExp.exec(field_enrollment_package_name);

    if (!matches) { return; }

    return matches[1];
  }

  displayProgressSpinner(boolean: boolean) {
    this.showProgressSpinner = boolean;
  }

  // Select edit mode
  selectFormIndex = (index: number) => {
    // console.log('selectFormIndex called...')
    // console.log(index)
    this.selectedIndex = index;
  }

  handleError(error: any) {
    console.log("error");
    console.log(error);
    this.displayProgressSpinner(false);

    // Default error.
    this.errorMessage = "There was an error.";

    // Handle authentication errors.
    if (error.status == 400 && error.error.message == "Sorry, unrecognized username or password.") {
      this.successMessage = "";
      this.errorMessage = "Sorry, unrecognized username or password.";
    }

    if (error.status == 302) {
      // this.errorMessage = error.error.message;
      console.log('this means user logged in')
    }

    // Handle authentication errors.
    if (error.status == 403) {
      this.errorMessage = error.error.message;
      // User needs to login.
      if (error.error.message == "This route can only be accessed by anonymous users.") {
        // User is already logged in, forward to the dashboard.
        this._authService.set_user_authenticated(true);
        this._router.navigate(['/app/splash'], { queryParams: { logged_out_inactive: true } })
      } else {
        this._authService.set_user_authenticated(false);
        this._router.navigate(['/auth/login'], { queryParams: { logged_out_inactive: true } })
      }
    }

    // Handle validation errors.
    if (error.status == 406) {
      this.errorMessage = error.error.message;
    }

    // Handle success. Right now 0 is our success code :/
    if (error.status == 0 || error.status == 500) {
      // this.closeDialog();
      return;
    }

    // Emit the data since API doesn't give us correct validation response.
    this.emitDataChangedEvent();

    // Close the dialog
    if (this.actionType == "delete") {
      // this.closeDialog();
    }
  }

  // Update the form state.
  setFormMode(mode: "create" | "view" | "edit" | "delete" | number | "refund") {
    console.log('setFormMode called...', mode)
    if (mode == "view" || mode == 0) {
      this.selectFormIndex(0);
      this.actionType = "view";
    }

    if (mode === "create") {
      this.selectFormIndex(1);
      this.actionType = "create";
    }

    if (mode === "edit" || mode === 1) {
      this.selectFormIndex(1);
      this.actionType = "edit";
    }

    if (mode === "refund") {
      this.selectFormIndex(1);
      this.actionType = "refund";
    }

    if (mode === "delete" || mode === 2) {
      this.selectFormIndex(2);
      this.actionType = "delete";
    }

    if (Number.isInteger(mode) && mode > 2) {
      // Custom form index.
      this.selectFormIndex(mode as number);
    }

    // Clear error message on state change.
    this.errorMessage = "";
  }

  ngAfterContentInit() {
  }

  onStudentNameSelectThenLoadEnrollmentName($studentID: { option: { value: any; }; }, showSundryMisc = false, $currentEnrollmentID = null, setOldestEnrollmentAsDefault = false, actionType: string = null) {
    this.enrollmentsForUser = [];

    let enrollmentBalanceAboveZero!: boolean;
    let studentID = ($studentID?.option?.value ? $studentID.option.value : $studentID);
    let endpoint = "/api_rest/v1/enrollmentLookupDetailed";

    // Extract the student ID from parenthesis.
    let regExp = /\(([^)]+)\)(?!.*\()/;
    let matches = regExp.exec(studentID);

    if (!studentID) { return };
    if (matches == null) { return };

    switch (this.initActionType) {
      case 'create':
        // For 'create' we only show available enrollments.
        enrollmentBalanceAboveZero = true;
        break;
      default:
        enrollmentBalanceAboveZero = true;
        break;
    }

    let params = [
      { parameter: "u", value: matches[1] },
    ]

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe(data => {
        console.log('enrollmentData', data);
        let dataObj = Object.values(data);
        let results;

        switch (this.initActionType) {
          case 'create':
            // Load only the necessary enrollments in create mode.
            results = dataObj.filter(object => {
              // (Payment amount + Dropped amount) == Total price of enrollment
              let isPaymentPlusDropEqualTotalPrice = !(object.field_drop[0]?.value == object.field_balance_due[0]?.value);
              // Are lessons available for the enrollment?
              let isLessonAvailable =  Number(object.field_lesson_available[0]?.value) >= 0; // Check if lessons are greater than 0

              if (actionType == 'createLesson') {
                isLessonAvailable = Number(object.field_lesson_available[0]?.value) > 0;
              }
              // Filter for sundry or misc enrollments.
              let isSundryOrMisc = (object?.field_category?.[0]?.target_id == 67) || (object.field_category[0].target_id == 5);
              // field_total_payments_made == field_enrollment_total_price AND field_lesson_available == 0
              let isLessonTakenAndPaid = (object.field_lesson_available[0]?.value == 0 && object.field_balance_due[0]?.value == 0);
              if (isLessonTakenAndPaid) {
                return false;
              }
              if (showSundryMisc) {
                return isLessonAvailable && isPaymentPlusDropEqualTotalPrice;
              }

              return isLessonAvailable && isPaymentPlusDropEqualTotalPrice && !isSundryOrMisc;
            });
            break;
          case 'edit':
            // Load only the necessary enrollments in edit mode.
            results = dataObj.filter(object => {
              // Check if this object is the current enrollment.
              let isCurrentEnrollment = $currentEnrollmentID && object.id === $currentEnrollmentID;

              // If it is the current enrollment, bypass all checks and include it.
              if (isCurrentEnrollment) {
                return true;
              }

              // Otherwise, apply the rest of the conditions.
              // (Payment amount + Dropped amount) != Total price of enrollment
              let isPaymentPlusDropEqualTotalPrice = !(object.field_drop[0]?.value == object.field_balance_due[0]?.value);
              // Are lessons available for the enrollment?
              let isLessonAvailable = object.field_lesson_available[0]?.value;
              // Filter for sundry or misc enrollments.
              let isSundryOrMisc = (object.field_category[0].target_id == 67) || (object.field_category[0].target_id == 5);

              if (showSundryMisc) {
                return isPaymentPlusDropEqualTotalPrice;
              } else {
                return isPaymentPlusDropEqualTotalPrice && !isSundryOrMisc;
              }
            });
            break;
          case 'refund':
            // Load all enrollments by default.
            results = dataObj.filter(object => {
              return true;
            });
            break;
          default:
            // Load all enrollments by default.
            results = dataObj.filter(object => {
              return true;
            });
            break;
        }

        this.enrollmentsForUser = results;

        // Set the oldest enrollment as the default value in the form
        if (setOldestEnrollmentAsDefault && results.length > 0) {
          // Sort the results array based on the field_sale_date field
          results.sort((a, b) => {
            const dateA = new Date(a.field_sale_date[0].value);
            const dateB = new Date(b.field_sale_date[0].value);
            return dateA.getTime() - dateB.getTime();
          });
          // Get the ID of the oldest enrollment
          const oldestEnrollmentId = results[0].id[0].value;
          // Set the oldest enrollment as the default value in the form
          this.f.form.controls['__field_students_inline_form'].patchValue({
            field_enrollment: oldestEnrollmentId
          });
        }
      });
  }

  // Handle the autocomplete for student field.
  onAutocompleteStudent($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = "student_accounts"; // student_accounts
    let bundle: string = "student_account"; // student_account
    let field: string = "title";
    this._utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompleteStudentOptions = data;
      })
  }

  // Handle the autocomplete for student field.
  onAutocompletePackage($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = "packages";
    let bundle: string = "package";
    let field: string = "title";
    this._utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompletePackageOptions = data;
      })
  }

  // Handle the autocomplete for student field.
  onAutocompleteContactEmail($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = "contacts";
    let bundle: string = "student_record";
    let field: string = "field_email";
    this._utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompleteEmailOptions = data;
      })
  }

  // Handle the autocomplete for student field.
  onAutocompleteContactPhone($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = "contacts";
    let bundle: string = "student_record";
    let field: string = "field_cell_phone";
    this._utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompletePhoneOptions = data;
      })
  }

  onAutoCompleteBaseLesson($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = "events"; //
    let bundle: string = ""; //
    let field: string = "title";

    this._utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompleteBaseLessonOptions = data;
      })
  }

  // Handle the autocomplete for student account field.
  onAutocompleteStudentContact($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = "contacts"; //
    let bundle: string = "student_record"; //
    let field: string = "title";
    this._utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompleteStudentContactOptions = data;
      })
  }

  // Handle the autocomplete for package field.
  onAutocompletePackages($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = 'packages';
    let bundle: string = 'package';
    let field: string = 'title';

    this._utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompletePackageOptions = data;
      });
  }

  getUniquePaymentID() {
    let endpoint = "/api_rest/v1/getUniquePaymentID";
    return this._drupalRESTService.httpGET(endpoint);
  }

  uiUpdateDate($event: any) {
    console.log("uiUpdateDate called...");
    let date = $event;

    if ($event == "") { return }

    // Convert date to Drupal friendly date.
    // this.field_date_and_time = moment(date).format('YYYY-MM-DD[T]HH:mm:ss')
  }

  uiUpdateDateAlt($event: any) {
    console.log("uiUpdateDateAlt called...");
    if ($event == "") { return }

    let date = $event;

    // Convert date to Drupal friendly date.
    this.field_expiration = moment(date).format('YYYY-MM-DD');
  }


  // Returns only numbers.
  _filterDigits(string: string) {
    // return string.replace(/\D/g, '');
    // Keep the decimal.
    return string.replace(/[^\d.]/g, '');
  }

  // Returns only strings.
  _filterStrings(string: string) {
    return string.replace(/\s*\(\d+\)/g, '')
  }

  lookupStudentAccountContact(student_account: { option: { value: string; }; }, selectFirstContact = false) {
    console.log("lookupStudentAccountContact called...", student_account);
    let contacts = "";
    let contactsID = [];
    let tmp = [];
    let selectOptions = [];

    // Fix for console logging undefined error.
    if (student_account == null) { return; }

    let studentID = (student_account?.option?.value ? student_account.option.value : student_account).toString();

    this._entityRESTService.getEntity('student_accounts', 'student_account', this.regexStudentName(studentID))
      .subscribe(data => {
        console.log('data', data);
        // Support for couples (multiple contacts)
        if (Array.isArray(data['field_contacts'])) {
          data['field_contacts'].forEach(element => {
            contactsID.push(element.id);
            tmp.push(element.title + ' (' + element.id + ')');
            selectOptions.push({ id: element.id, title: element.title });
          });

          contacts = tmp.join(', ');

          selectOptions.push({ id: contactsID.toString(), title: contacts });
        } else {
          // For a single contact.
          selectOptions.push({ id: data['field_contacts'].id, title: data['field_contacts'].title });
          contacts += data['field_contacts'].title + ' (' + data['field_contacts'].id + ')';
        }

        this.autoCompleteStudentContactOptions = selectOptions;
        this.f.form.controls['__field_students_inline_form'].patchValue({
          field_students_contact: contacts
        });

        // Automatically select the first contact for field_students
        if (selectOptions.length > 0 && selectFirstContact) {
          const firstContactId = selectOptions[0].id;
          this.f.form.controls['__field_students_inline_form'].patchValue({
            field_students: firstContactId
          });

          // Set field_type based on field_student_department
          const studentDepartment = data['field_student_department'];
          if (studentDepartment == 75) {
            this.f.form.patchValue({
              field_type: "548"
            });
          } else if (studentDepartment == 77) {
            this.f.form.patchValue({
              field_type: "600"
            });
          }
        }
      });
  }

  redirectUserProfileID(value: { value: string; }) {
    let accountID = parseInt(value.value)
    console.log('redirectUserProfileID called...', value.value, accountID)

    if (!accountID) { return; }

    this._router.navigate(['/students/' + value.value]);
  }

  redirectUserProfile(value: { value: string; }) {
    let accountID = this.regexStudentName(value.value)
    console.log('redirectUserProfile called...', value.value, accountID)

    if (!accountID) { return; }

    // Load the student account entity to get their user accounts.
    this._entityRESTService.getEntity('student_accounts', 'student_account', accountID).subscribe(data => {
      // console.log('data', data)
      if (data?.['field_contacts']?.['id']) {
        this._router.navigate(['/students/' + data?.['field_contacts']?.['id']]);
      }

      // Support multiple student account contacts.
      if (data?.['field_contacts']?.['0']?.id) {
        this._router.navigate(['/students/' + data?.['field_contacts']?.['0']?.id]);
      }
    })
  }

  openDrupalLink(link) {
    let endpoint = environment.drupalUrl;
    window.open(endpoint + link, '_newtab');
  }

  is_array(obj) {
    return Array.isArray(obj);
  }

  convertToArray(obj) {
    return _.toArray(obj);
  }

  objLength(obj) {
    if (obj) {
      return Object.keys(obj).length;
    } else {
      return null;
    }
    return (obj && (Object.keys(obj).length === 0));
  }

  getFormErrors(form: AbstractControl) {
    if (form instanceof FormControl) {
      return form.errors ?? null;
    }
    if (form instanceof FormGroup || form instanceof FormArray) {
      const groupErrors = form.errors;
      const formErrors = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach(key => {
        // Recursive call of the FormGroup fields
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          formErrors[key] = error;
        }
      });
      // Return errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

  // Refresh FullCalendar.
  refreshCalendar(refresh = 0) {
    setTimeout(() => {
      if (this?.calendarComponent?.getApi()) {
        this.calendarComponent.getApi().refetchEvents();
        this.calendarComponent.getApi().refetchResources();
      }
    }, refresh);
  }

  refreshCalendar2(refresh = 0) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this?.calendarComponent?.getApi()) {
          this.calendarComponent.getApi().refetchEvents();
          this.calendarComponent.getApi().refetchResources();
          resolve(true); // Resolve the promise once done
        } else {
          resolve(false); // Resolve the promise if calendar API is not available
        }
      }, refresh);
    });
  }

  /*
  *  This function is used to validate all form controls.
  *  It is used in conjunction with the formGroupDirective.
  *  This function is called when the form is submitted.
  */
  validateAllFormControl(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormControl(control);
      }
    });
  }

  /**
   * This function is to validate form controls recursively.
   *
   * @param formToInvestigate
   * @returns
   */
  findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
    var invalidControls: string[] = [];
    let recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control.invalid) invalidControls.push(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    }
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  /* Invalid controls for a template driven form. */
  getInvalidControls(form: NgForm) {
    const invalid = [];
    const controls = form.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push({ name, errors: controls[name].errors });
      }
    }
    return invalid;
  }

  showSnackbar(message: string): void {
    this._snackBar.open(message, 'Close', {
      duration: 5000, // Duration in milliseconds after which the snackbar will be dismissed
    });
  }

  roundToNearest15Min(dateTime: moment.Moment = moment()) {
    const minutes = dateTime.minute();
    const roundedMinutes = Math.ceil(minutes / 15) * 15 % 60;
    const hours = dateTime.hour() + Math.floor(minutes / 45);

    return dateTime.clone().minute(roundedMinutes).second(0).hour(hours);
  }

  onDateTimeInputClick() {
    if (this.field_date_and_time && moment(this.field_date_and_time, moment.ISO_8601, true).isValid()) {
      const dateTime = moment(this.field_date_and_time);
      this.pickerFieldDateTime._selected = dateTime;
    } else {
      const dateTime = this.roundToNearest15Min(moment());
      this.field_date_and_time = dateTime.format('YYYY-MM-DD[T]HH:mm:ss');
      this.pickerFieldDateTime._selected = dateTime;
      this.f.form.controls['field_date_and_time'].patchValue(this.field_date_and_time);
    }
    this.pickerFieldDateTime.open();
  }

  onDateTimeToggleClick() {
    if (this.field_date_and_time && moment(this.field_date_and_time, moment.ISO_8601, true).isValid()) {
      const dateTime = moment(this.field_date_and_time);
      this.pickerFieldDateTime._selected = dateTime;
    }
  }

  onDateChange(event: any) {
    let dateTime;
    if (event && moment(event, moment.ISO_8601, true).isValid()) {
      dateTime = moment(event);
      const isValidIncrement = dateTime.minute() % 15 === 0 && dateTime.second() === 0;
      if (!isValidIncrement) {
        dateTime = this.roundToNearest15Min(dateTime);
      }
    } else {
      dateTime = this.roundToNearest15Min(moment());
    }

    const newDateTime = dateTime.format('YYYY-MM-DD[T]HH:mm:ss');
    if (this.field_date_and_time !== newDateTime) {
      this.field_date_and_time = newDateTime;
      this.f.form.controls?.['field_date_and_time']?.patchValue(this.field_date_and_time);
    }
  }
}
