import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { PackageEntityComponent } from '../../forms/package-entity/package-entity.component';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.css']
})
export class PackageListComponent extends ViewTableComponent implements OnInit {

  // Other component refs.
  PackageEntityComponent = PackageEntityComponent

  override displayedColumns: string[] = [
    'id',
    'field_abbreviation',
    'field_lesson_count_value_1',
    'field_lesson_price_value',
    'field_lesson_type',
    'field_package_name',
    'field_sps_code',
    'field_total_price_value',
    'field_custom_text',
  ];

  activeLink = 'Active Packages';
  links = [
    {linkTitle: 'Active Packages', linkRoute: '/packages-list' },
    {linkTitle: 'Retired Packages', linkRoute: '/packages-list-retired' }
  ]

  navLinktest(link) {
    this._router.navigate(['auth/login']);
    // this._router.navigate(['packages-list-retired'])
  }

  openPackageEntityComponent(action: string, EntityID?) {
    this._dialogService.openDialog(PackageEntityComponent, "defaultWithData", {
      data: {
        EntityID: EntityID,
        action: action,
      },
    }).afterClosed().subscribe(data => {
      this.getData(this.queryParams);
    });
  }

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Add retired parameters.
    params = [
      ...params,
      { parameter: "_format", value: "json" },
      // Add retired parameters.
      { parameter: "field_retired_op", value: "=" },
      { parameter: "field_retired_value", value: "0" }
    ]

    // Load the new data with the filtering parameters.
    this._utilityService.getPackagesListView(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
        error => this.handleError(error)
      )
  }

}
