<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
	<div class="row">
		<div style="background:#215DAB; height: calc(100vh - 64px); overflow: scroll;" class="col-2">
			<app-configuration-dashboard-menu></app-configuration-dashboard-menu>
		</div>

		<div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">

			<div class="tutorials-layout">
				<!-- Sidebar Navigation -->
				<div class="tutorials-nav">
					<div class="nav-header">
						<h2>Tutorials</h2>
					</div>
					<div class="category-list">
						<div *ngFor="let category of categories"
							 [class.active]="selectedCategory === category"
							 (click)="selectCategory(category)"
							 class="category-item">
							<span class="category-icon">{{ category.icon }}</span>
							<span class="category-name">{{ category.name }}</span>
						</div>
					</div>
				</div>

				<!-- Main Content -->
				<div class="tutorials-content">
					<div class="content-header">
						<h3>{{ selectedCategory.name }}</h3>
					</div>

					<div class="tutorials-grid" *ngIf="!selectedTutorial">
						<div *ngFor="let tutorial of selectedCategory.tutorials"
							 class="tutorial-card"
							 (click)="selectTutorial(tutorial)">
							<h4>{{ tutorial.title }}</h4>
							<button class="view-tutorial-btn">View Tutorial</button>
						</div>
					</div>

					<div class="tutorial-viewer" *ngIf="selectedTutorial">
						<button class="back-btn" (click)="selectedTutorial = null">← Back to {{ selectedCategory.name }}</button>
						<h4>{{ selectedTutorial.title }}</h4>
						<app-tutorial-single [tutorialData]="selectedTutorial"></app-tutorial-single>
					</div>
				</div>
			</div>

		</div>

	</div>
</div>
