import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

interface StaffSuccessCategory {
  '# Tried': number | null;
  '# Sold': number | null;
  '% Sold (Wk)': number | null;
  '# Tried YTD': number | null;
  '# Sold YTD': number | null;
  '% Sold (YTD)': number | null;
}

interface InstructorSuccessData {
  instructor_name: string;
  categories: {
    DE: StaffSuccessCategory;
    PRE: StaffSuccessCategory;
    ORI: StaffSuccessCategory;
    EXT: StaffSuccessCategory;
    REN: StaffSuccessCategory;
    MISC: StaffSuccessCategory;
    NON: StaffSuccessCategory;
    SUN: StaffSuccessCategory;
  };
}

@Component({
  selector: 'app-staff-success-report',
  templateUrl: './staff-success-report.component.html',
  styleUrls: ['./staff-success-report.component.css']
})
export class StaffSuccessReportComponent extends ViewTableComponent implements OnInit {
  override displayedColumns: string[] = [
    'category',
    'tried',
    'sold',
    'percentWk',
    'triedYtd',
    'soldYtd',
    'percentYtd'
  ];

  year: string;
  week: string;
  override instructorData: InstructorSuccessData[] = [];

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year'];
      } else {
        this.year = moment().format("YYYY");
      }

      if (data['week']) {
        this.week = data['week'];
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    });
  }

  onYearChange(event: any): void {
    this.year = event.value;
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number.parseInt(this.year));
  }

  override getData(params: { parameter: string; value: string; }[]): void {
    this.ShowProgressBar = true;
    this.queryParams = params;

    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams);
    }

    this._drupalRESTService.httpGET('/api_rest/v1/staff_success_report', params)
      .subscribe({
        next: (response) => {
          this.ShowProgressBar = false;
          this.instructorData = Object.entries(response).map(([instructor_name, categories]) => ({
            instructor_name,
            categories: categories as InstructorSuccessData['categories']
          }));
        },
        error: (error) => {
          this.ShowProgressBar = false;
          console.error('Error fetching staff success data:', error);
        }
      });
  }

  refreshData(): void {
    this.getData(this.queryParams);
  }

  exportReport(): void {
    // Implement export functionality
    console.log('Exporting report...');
  }

  override resetForm(form?: NgForm): void {
    if (form) {
      form.resetForm();
    }
    this.getData([]);
  }

  getCategoryData(instructor: InstructorSuccessData) {
    if (!instructor || !instructor.categories) {
      return [];
    }

    return Object.entries(instructor.categories).map(([category, data]) => ({
      category,
      ...data
    }));
  }
}
