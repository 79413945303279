import { Component, OnInit } from '@angular/core';

import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// Services
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ComponentType } from '@angular/cdk/overlay';
import { BaseComponent } from '../forms/base-form/base-form.component';
import { StudentInquiryEntityComponent } from '../forms/student-inquiry-entity/student-inquiry-entity.component';
import { StudentAccountEntityComponent } from '../forms/student-account-entity/student-account-entity.component';
import { SupportRequestComponent } from '../forms/support-request/support-request.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent extends BaseComponent {

  // logo: string = "assets/images/logo.png";
  logo: string = "assets/images/logo-white.png";
  studios: any;
  selectedStudio: string;
  navbarOpen = false;
  studioSearchText: string = '';
  filteredStudios: any[] = [];

  StudentInquiryEntityComponent = StudentInquiryEntityComponent;
  StudentAccountEntityComponent = StudentAccountEntityComponent;
  supportRequestForm = SupportRequestComponent;

    /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
    public openDialog(formName: ComponentType<unknown>, configName?, data?) {
      this._dialogService.openDialog(formName, configName, data)
      .afterClosed()
      .subscribe(data => {
      });
    }

  ngOnInit(): void {
    // Load the studio list for administrators.
    this._drupalRESTService.httpGET('/api_rest/v1/loadStudioList').subscribe((data: any) => {
      this.studios = data;
      this.filteredStudios = [...this.studios]; // Initialize with all studios
    });
    // this.getMenu();
  }

  filterStudios() {
    if (!this.studioSearchText?.trim()) {
      // If search is empty, show all studios
      this.filteredStudios = [...this.studios];
    } else {
      const searchText = this.studioSearchText.toLowerCase().trim();
      this.filteredStudios = this.studios.filter(studio =>
        studio.key.toLowerCase().includes(searchText)
      );
    }
  }

  openAdminLink(link) {
    let endpoint = environment.hostUrl;
    window.open(endpoint + link, '_newtab');
  }

  openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    // console.log('fieldsData')
    // console.log(fieldsData)
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      if (this?.refreshCalendar) {
        this.refreshCalendar();
      }
    });
  }

  private loadMenu() {
    // this._menuREST.loadedMenuItems
  }

  // Switch studios functionality.
  handleStudioClick(studio) {
    this._authService.studios = [studio];
    this.navbarOpen = false;  // Close mobile navbar if open
    this.studioSearchText = '';  // Clear the search text
    this.filteredStudios = [...this.studios];  // Reset the filtered list

    // Refresh Instructor Data.
    this._fieldsService.fetchAndSetInstructorData();

    // Capture the current route.
    const currentRoute = this._router.url;

    // Navigate to a dummy route and then back to the current route to force reload.
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentRoute]);
    });
  }

  get currentStudioID(): string {
    return this._authService.studios?.[0]?.id;
  }

  get currentStudio(): string {
    return this._authService.studios?.[0]?.name || this._authService.studios?.[0]?.key;
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  closeNavbar() {
    this.navbarOpen = false;
  }

}
