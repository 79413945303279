<div class="container">
  <h2>Simple Import Spreadsheet</h2>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Step 1: Download Spreadsheet Template</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button mat-raised-button color="primary" (click)="downloadSpreadsheet()">
        Download Template
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Step 2: Import Completed Spreadsheet</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <input type="file" (change)="onFileSelected($event)" accept=".xlsx" style="display: none" #fileInput>
      <button mat-stroked-button (click)="fileInput.click()" [disabled]="isUploading">
        <mat-icon>attach_file</mat-icon>
        Select File
      </button>
      <span *ngIf="selectedFile">{{ selectedFile.name }}</span>
      <button class="ms-2" mat-raised-button color="primary" (click)="uploadSpreadsheet()" [disabled]="!selectedFile || isUploading">
        <mat-icon class="spinner" *ngIf="isUploading">refresh</mat-icon>
        <span>{{ isUploading ? 'Processing...' : 'Upload Spreadsheet' }}</span>
      </button>
      <button class="ms-2" mat-stroked-button color="warn" (click)="clearForm()" [disabled]="isUploading">
        <mat-icon>clear</mat-icon>
        Clear
      </button>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf="lastImportResult">
    <mat-card-header>
      <mat-card-title>Import Results</mat-card-title>
      <mat-card-subtitle>{{ lastImportResult.timestamp }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>{{ lastImportResult.message }}</p>
      <p>Total records processed: {{ lastImportResult.result.processed }}</p>
      <h3>Created:</h3>
      <ul>
        <li>Student Accounts: {{ lastImportResult.result.created.student_accounts }}</li>
        <li>Enrollments: {{ lastImportResult.result.created.enrollments }}</li>
        <li>Payments: {{ lastImportResult.result.created.payments }}</li>
        <li>Lessons: {{ lastImportResult.result.created.lessons }}</li>
      </ul>
      <h3>Updated:</h3>
      <ul>
        <li>Student Accounts: {{ lastImportResult.result.updated.student_accounts }}</li>
        <li>Enrollments: {{ lastImportResult.result.updated.enrollments }}</li>
        <li>Payments: {{ lastImportResult.result.updated.payments }}</li>
        <li>Lessons: {{ lastImportResult.result.updated.lessons }}</li>
      </ul>
      <h3>Deleted:</h3>
      <ul>
        <li>Student Accounts: {{ lastImportResult.result.deleted.student_accounts }}</li>
      </ul>
      <h3 *ngIf="lastImportResult.result.errors.length > 0">Errors:</h3>
      <ul *ngIf="lastImportResult.result.errors.length > 0">
        <li *ngFor="let error of lastImportResult.result.errors">{{ error }}</li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="lastImportResult?.result?.student_accounts?.length" class="mt-4">
    <mat-card-header>
      <mat-icon mat-card-avatar class="text-primary">people</mat-icon>
      <mat-card-title>Step 3: View Imported Students</mat-card-title>
      <mat-card-subtitle>
        Successfully imported {{ lastImportResult.result.student_accounts.length }}
        {{ lastImportResult.result.student_accounts.length === 1 ? 'student' : 'students' }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="mt-3">
      <table mat-table [dataSource]="lastImportResult.result.student_accounts" class="w-100">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Student Name</th>
          <td mat-cell *matCellDef="let student">{{ student.name }}</td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Student ID</th>
          <td mat-cell *matCellDef="let student">{{ student.id }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let student">
            <button mat-flat-button color="primary"
                    (click)="openStudentDashboard(student)"
                    matTooltip="Open Student Dashboard">
              <mat-icon class="me-2">dashboard</mat-icon>
              View Dashboard
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['name', 'id', 'actions']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'id', 'actions'];"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
