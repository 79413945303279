<div class="container-fluid">
    <h1 class="page-header">Enrollment List</h1>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filter
            </mat-panel-title>
            <mat-panel-description>
                <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
            <div class="row">

                <div class="col-2">
                    <!-- <mat-form-field class="full-width-field">
                        <mat-label>Instructor</mat-label>
                        <input
                            name="instructor"
                            (input)="onAutocompleteInstructor($event)"
                            matInput
                            ngModel
                            [matAutocomplete]="autoCompleteInstructor"
                            >
                        <button type="button" matSuffix mat-icon-button
                            aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field> -->
                    <!-- <mat-autocomplete #autoCompleteInstructor="matAutocomplete"
                        (optionSelected)="onSubmit(f)">
                        <mat-option
                            *ngFor="let option of autocompleteInstructorOptions"
                            [value]="option.label + ' ' + '(' + option.value + ')'">
                            {{option.label}} ({{option.value}})
                        </mat-option>
                    </mat-autocomplete> -->
                    <mat-form-field class="full-width-field">
                        <mat-label>Instructor</mat-label>
                        <mat-select
                        matInput
                        ngModel
                        name="instructor"
                        (selectionChange)="onSubmit(f)">
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let element of this._fieldsService.instructorData()"
                                [value]="element.uid">
                                {{ element.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Student</mat-label>
                        <input
                            #studentInput
                            name="title"
                            (input)="onAutocompleteStudent($event)"
                            matInput
                            ngModel
                            [matAutocomplete]="autoCompleteStudent"
                            >
                        <button (click)="redirectUserProfile(studentInput)" type="button" matSuffix mat-icon-button
                            aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-autocomplete #autoCompleteStudent="matAutocomplete"
                        (optionSelected)="onSubmit(f)">
                        <mat-option
                            *ngFor="let option of autoCompleteStudentOptions"
                            [value]="option.label">
                            {{option.label}} ({{option.value}})
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Sale Date</mat-label>
                        <input matInput
                        (click)="picker.open()"
                        [ngxMatDatetimePicker]="picker"
                        name="field_sale_date_value"
                        ngModel
                        (dateChange)="onSubmit(f)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker
                            [hideTime]="true"
                            [enableMeridian]="true"
                            [showSpinners]="true"
                            [showSeconds]="false"
                            [stepHour]="1" [stepMinute]="15"
                            [touchUi]="false">
                        </ngx-mat-datetime-picker>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <!-- <mat-form-field class="full-width-field">
                        <mat-label>Category</mat-label>
                        <input
                            name="field_category_target_id"
                            (input)="onViewsAutocompleteFiltersCategory($event)"
                            matInput
                            ngModel
                            [matAutocomplete]="autoCompleteCategory"
                            >
                        <button type="button" matSuffix mat-icon-button
                            aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-autocomplete #autoCompleteCategory="matAutocomplete"
                        (optionSelected)="onSubmit(f)">
                        <mat-option
                            *ngFor="let option of autoCompleteCategoryOptions"
                            [value]="option.label">
                            {{option.label}} ({{option.value}})
                        </mat-option>
                    </mat-autocomplete> -->

                    <!-- <mat-form-field class="full-width-field">
                        <mat-label>Instructor</mat-label>
                        <mat-select
                        matInput
                        ngModel
                        name="instructor"
                        (selectionChange)="onSubmit(f)">
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let element of this._fieldsService.instructorData()"
                                [value]="element.uid">
                                {{ element.value }}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field class="full-width-field">
                        <mat-label>Category</mat-label>
                        <mat-select (selectionChange)="onSubmit(f)" ngModel matInput name="field_category_target_id">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let element of this._taxonomyService.enrollment_type"
                                [value]="element.name">
                                {{ element.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Category</mat-label>
                        <input
                            matInput
                            ngModel
                            name="field_category_target_id"
                            (blur)="onSubmit(f)"
                            (selectionChange)="onSubmit(f)">
                    </mat-form-field>
                </div> -->

                <!-- Packages Autocomplete -->
                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Package Name</mat-label>
                        <input
                            name="field_package_id_value"
                            (input)="onAutocompletePackage($event)"
                            matInput
                            ngModel
                            [matAutocomplete]="autoCompletePackages">
                        <button type="button" matSuffix mat-icon-button
                            aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-autocomplete #autoCompletePackages="matAutocomplete"
                        (optionSelected)="onSubmit(f)">
                        <mat-option
                            *ngFor="let option of autoCompletePackageOptions"
                            [value]="option.value">
                            {{option.label}} ({{option.value}})
                        </mat-option>
                    </mat-autocomplete>
                </div>


                <!-- <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Lessons Paid</mat-label>
                        <input
                            matInput
                            ngModel
                            name="field_lessons_paid_value"
                            (blur)="onSubmit(f)"
                            (selectionChange)="onSubmit(f)">
                    </mat-form-field>
                </div> -->

                <!-- <div class="col-2">
            <mat-form-field class="full-width-field">
            <mat-label>Sort by</mat-label>
            <mat-select
                matInput
                ngModel
                name="sort_by"
                (blur)="onSubmit(f)"
                (selectionChange)="onSubmit(f)">
                <mat-option value="field_total_price_value" selected="selected">Total Price</mat-option>
                <mat-option value="field_lesson_count_value_1">Lesson Count</mat-option>
                <mat-option value="field_lesson_price_value">Lesson Price</mat-option>
            </mat-select>
            </mat-form-field>
        </div> -->
                <!-- <div class="col-2">
            <mat-form-field class="full-width-field">
            <mat-label>Order by</mat-label>
            <mat-select
                matInput
                ngModel
                name="sort_order"
                (blur)="onSubmit(f)"
                (selectionChange)="onSubmit(f)">
                <mat-option value="ASC" selected="selected">Asc</mat-option>
                <mat-option value="DESC">Desc</mat-option>
            </mat-select>
            </mat-form-field>
        </div> -->
                <section class="section">

                    <div class="float-end bring-inline">
                        <button (click)="resetForm()" color="primary"
                            mat-flat-button
                            class="me-1" mat-button>Clear
                            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
                        </button>
                    </div>

                </section>


            </div>


            <section class="section">
            </section>

        </form>

    </mat-expansion-panel>

    <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

    <div class="row">
        <div class="col-md-12 mt-3">
            <div class="float-end">
                <button class="enrollment" mat-flat-button
                    (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'create', null)" mat-button>
                    Add Enrollment
                </button>
            </div>
        </div>
    </div>

    <!-- <mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field> -->

    <!-- <div class="example-container mat-elevation-z8">
    <div class="loading-shade"
    *ngIf="ShowProgressBar">
    <mat-spinner *ngIf="ShowProgressBar"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        No limit
    </div>
    </div>
    </div> -->

    <div class="table-responsive">

        <table mat-table #displayTable [dataSource]="dataSource"
            matSort
            (matSortChange)="sortData($event)"
            class="mt-4">

            <!-- enrollmentID Column -->
            <ng-container matColumnDef="enrollmentID">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by enrollmentID">
                    ID
                </th>
                <td mat-cell *matCellDef="let element"> {{element.id}}
                </td>
            </ng-container>

            <!-- field_contacts Column -->
            <ng-container matColumnDef="field_contacts">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_contacts">
                    Student
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.field_is_gift_certificate; else regularStudent">
                        Gift Certificate
                    </ng-container>
                    <ng-template #regularStudent>
                        <a
                        class="cursor-pointer"
                        (click)="openDialog(StudentDashboardWrapperComponent, 'largerScreenDashboard', {
                            student_id: element.student_contact_id
                        })"
                        >
                        {{ element.field_contacts }}
                        </a>
                    </ng-template>
                </td>
            </ng-container>

            <!-- field_sale_date Column -->
            <ng-container matColumnDef="field_sale_date">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_sale_date">
                    Sale Date
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.field_sale_date | date: 'MM/dd/yyyy' }} </td>
            </ng-container>

            <!-- field_instructor Column -->
            <ng-container matColumnDef="field_instructor">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_instructor">
                    Instructor
                </th>
                <td mat-cell *matCellDef="let element">
                    <!-- {{ element.field_instructor }}  -->
                    {{ element.field_first_name | htmlDecode }}
                    {{ element.field_last_name | htmlDecode }}
                </td>
            </ng-container>

            <!-- field_category Column -->
            <ng-container matColumnDef="field_category">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_category">
                    Category
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.field_category }}
                </td>
            </ng-container>

            <!-- field_package_name Column -->
            <ng-container matColumnDef="field_package_name">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_package_name">
                    Package Name
                </th>
                <td mat-cell *matCellDef="let element"> {{
                    element.field_enrollment_package_name }} </td>
            </ng-container>

            <!-- field_enrollment_lesson_count Column -->
            <ng-container matColumnDef="field_enrollment_lesson_count">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_enrollment_lesson_count">
                    # of Lessons
                </th>
                <td mat-cell *matCellDef="let element"> {{
                    element.field_enrollment_lesson_count }} </td>
            </ng-container>

            <!-- field_enrollment_total_price Column -->
            <ng-container matColumnDef="field_enrollment_total_price">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_enrollment_total_price">
                    Total Price
                </th>
                <!-- <td mat-cell *matCellDef="let element"> ${{ element.field_enrollment_total_price }} </td> -->
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.field_enrollment_total_price % 1 === 0; else notInteger">
                      ${{ element.field_enrollment_total_price | number:'1.0-0' }}
                    </span>
                    <ng-template #notInteger>
                      ${{ element.field_enrollment_total_price | number:'1.2-2' }}
                    </ng-template>
                </td>
            </ng-container>

            <!-- field_total_paid Column -->
            <ng-container matColumnDef="field_total_paid">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_total_paid">
                  Total Paid
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.field_total_payments_made % 1 === 0; else notInteger">
                      ${{ element.field_total_payments_made | number:'1.0-0' }}
                    </span>
                    <ng-template #notInteger>
                      ${{ element.field_total_payments_made | number:'1.2-2' }}
                    </ng-template>
                </td>
            </ng-container>

            <!-- field_balance_due Column -->
            <ng-container matColumnDef="field_balance_due">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_balance_due">
                    Total Due
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.field_balance_due % 1 === 0; else notInteger">
                      ${{ element.field_balance_due | number:'1.0-0' }}
                    </span>
                  <ng-template #notInteger>
                      ${{ element.field_balance_due | number:'1.2-2' }}
                    </ng-template>
                </td>
            </ng-container>

            <!-- field_additional_notes Column -->
            <ng-container matColumnDef="field_additional_notes">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_additional_notes">
                    Last Paid
                </th>
                <td mat-cell *matCellDef="let element">{{ element.field_last_paid_date != "" ? (element.field_last_paid_date | date) : '' | date }} </td>
            </ng-container>

            <!-- field_lesson_available Column -->
            <ng-container matColumnDef="field_lesson_available">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_lesson_available">
                    Lessons Available
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.field_lesson_available }} </td>
            </ng-container>

            <!-- field_lesson_available Column -->
            <ng-container matColumnDef="isDropped">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_lesson_available">
                    Drop
                </th>
                <td mat-cell *matCellDef="let element"><span class="text-nowrap">{{ element.field_dropped_date ? '✓' : ''  }} {{ element.field_dropped_date | date }}</span></td>
            </ng-container>

            <!-- nothing Column -->
            <!-- <ng-container matColumnDef="nothing">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by nothing">
                    Hide
                </th>
                <td mat-cell *matCellDef="let element">
                </td>
            </ng-container> -->

            <mat-menu #enrollmentMenu="matMenu">
                <ng-template matMenuContent let-enrollmentID="enrollmentID" let-student_account_id="student_account_id">
                    <button mat-menu-item
                        (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'view', enrollmentID)"
                        >
                        View
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'editEnrollment', enrollmentID)"
                        >
                        Edit
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(SignatureEnrollmentComponent, 'packages', 'enrollment', 'signEnrollment', enrollmentID)"
                        >
                        Sign
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'delete', enrollmentID)"
                        >
                        Delete
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(EnrollmentDropComponent, 'packages', 'enrollment', 'drop', enrollmentID)"
                        >
                        Drop
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'refund', null, {field_gross_tuition: '', field_enrollment_name: {target_id: enrollmentID }, field_student_name: student_account_id, field_payment_type: '71'})"
                        >
                        Refund
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponentXL(EnrollmentViewLessonComponent, 'packages', 'enrollment', '0', enrollmentID)"
                        >
                        View Lessons
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(ViewPaymentsFromEnrollmentComponent, 'packages', 'enrollment', null, enrollmentID)"
                        >
                        View Payments
                    </button>
                </ng-template>
            </mat-menu>

            <!-- nothing_1 Column -->
            <ng-container matColumnDef="nothing_1">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by nothing_1">
                    Actions
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="enrollmentMenu" [matMenuTriggerData]="{ enrollmentID: element.id, student_account_id: element.field_contacts + ' (' + element.student_account_id + ')'}">
                    <mat-icon>more_vert</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator
            [disabled]="ShowProgressBar"
            (page)="pageChanged($event)"
            [pageSizeOptions]="onPageSizeOptions()"
            [length]="30"
            [pageSize]="paginationTotalPages"
            [pageIndex]="paginationIndex"
            aria-label="Select page of view"></mat-paginator>

    </div>
</div>
